<template>
  <div class="popup-container" @click.self="$emit('close')">
    <div class="popup-content">
      <q-btn style="margin-left: 10%;" icon="close" flat round dense @click="$emit('close')" />
      
      <!-- Updated Text -->
      <h2 style="font-weight: bold;">Mindspeller : Search Beyond Words</h2>
      <img src="../../assets/mindsearchperson.png" alt="Step 1 Image" style="max-width: 165px; max-height: 200px; margin: auto;" />

      <ul class="highlight bullet-points">
        <strong>Your Benefits</strong><br>
        <li>
          What if your thoughts could power your search? With Mindspeller’s revolutionary EEG-powered search engine, you can explore the web just by thinking. The more you use it, the better it understands your mind, delivering results tailored to your unique way of thinking.
        </li>
        <li>Unlock a new era of search—faster, intuitive, and effortless.</li>
        <li>Earn back your hardware investment in rewards and discounts on your favorite brands by sharing your thoughts.</li>
        <li>Fill out the form to learn more about our EEG headset and be among the first to experience hands-free brand association decoding!</li>
      </ul>
      <p class="highlight">
        <strong>Your Investment</strong><br><br>
        🔹 Get your EEG headband now for just €259 upfront* <small><em>Excludes shipping and taxes</em></small><br>
        🔹 Turn insights into income for just €9/month with Pro Respondent access!<br><br>
      </p>
      <button class="cta-button" @click="scrollToForm">🔽 Order your EEG headset today and start searching with your mind! 🔽</button>

      <!-- Form Section -->
      <div class="form-container" ref="formContainer">
        <input type="text" v-model="name" placeholder="Name" />
        <input type="tel" v-model="email" placeholder="Email Address" />
        <input type="tel" v-model="phone" placeholder="Phone Number" />
        <textarea v-model="address" placeholder="Shipping Address"></textarea>
        <button class="submit-button mx-auto" @click="submitForm">Submit</button>
      </div>
    </div>
  </div>
</template>

<script>
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
import axios from 'axios';
export default {
  data() {
    return {
      name: "",
      email: "",
      phone: "",
      address: "",
    };
  },
  emits: ["close"],
  methods: {
    scrollToForm() {
      const formContainer = this.$refs.formContainer;
      formContainer.scrollIntoView({ behavior: 'smooth' });
    },
    async submitForm() {
      if (!this.name || !this.phone || !this.email || !this.address) {
        alert("Please fill in all the fields.");
        return;
      }

      const formData = {
        name: this.name,
        email: this.email,
        phone: this.phone,
        address: this.address,
      };

      try {
        const response = await axios.post('/api/cas/preorder_prorespondent', formData);

        if (response.status === 200) {
          toast_success(`You will be contacted shortly to confirm your pre-order. Thank you!`);
        } else {
          alert("Failed to submit the form. Please try again.");
        }
      } catch (error) {
        alert("An error occurred. Please try again later.");
        console.error(error);
      } finally {
        this.$emit("close");
      }
    },
  },
};
</script>

<style scoped>
.popup-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.popup-content {
  background: #ffffff;
  padding: 30px;
  padding-right: 38px;
  border-radius: 15px;
  max-width: 60%;
  width: 90%;
  max-height: 85%;
  overflow-y: auto;
  text-align: center;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-in-out;
  position: relative;
}

h2 {
  background-color: #7878e9;
  border: 1px solid #ddd;
  border-radius: 10px;
  /* margin-top: 5%; */
  width: 85%;
  color: white;
  margin: auto;
  margin-bottom: 3%;
  padding: 5px;
  font-size: 22px;
  text-align: center;
}

.popup-content::-webkit-scrollbar {
  width: 10px;
  margin-right: 10%;
}

.popup-content::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 10px;
}

.popup-content::-webkit-scrollbar-thumb {
  background: #d9e1ec;
  border-radius: 10px;
  border: 2px solid transparent;
  background-clip: padding-box;
}

.popup-content::-webkit-scrollbar-thumb:hover {
  background: #b8c7d9;
}

.popup-content .highlight {
  font-size: 14px;
  color: #333333;
  margin-bottom: 15px;
  line-height: 1.6;
  background-color: #f9f9f9;
  padding: 15px;
  text-align: left;
  border-left: 5px solid #7878e9;
  border-radius: 5px;
}
.bullet-points {
  list-style: none;
  background-color: #f9f9f9;
  padding: 15px;
  border-left: 5px solid #7878e9;
  border-radius: 5px;
}

.bullet-points li {
  margin: 10px 0;
  position: relative;
  padding-left: 25px;
}
.bullet-points li::before {
  content: "🔹";
  position: absolute;
  left: 0;
}

.benefits {
  text-align: left;
  margin: 15px 0;
  padding-left: 20px;
  color: #4a4a4a;
  font-size: 16px;
}

.benefits li {
  margin-bottom: 10px;
}

.how-it-works {
  margin: 20px 0;
}

.how-it-works h3 {
  font-size: 20px;
  text-align: left;
  font-weight: 600;
  color: #333333;
  margin-bottom: 10px;
}

.how-it-works ol {
  text-align: left;
  /* padding-left: 20px; */
  color: #4a4a4a;
  font-size: 16px;
  line-height: 1.6;
}

.spots-limited {
  font-size: 16px;
  color: #d9534f;
  margin-top: 15px;
}

.cta-button {
  background-color: #6a5acd;
  color: #ffffff;
  font-size: 16px;
  font-weight: bold;
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-top: 20px;
  transition: background-color 0.3s ease-in-out;
}

.cta-button:hover {
  background-color: #5940b3;
}

/* Form Section */
.form-container {
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.form-container input,
.form-container textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}

.form-container textarea {
  resize: none;
  height: 80px;
}

.submit-button {
  background-color: #28a745;
  color: #ffffff;
  font-size: 16px;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.submit-button:hover {
  background-color: #218838;
}

/* Fade-in animation */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: scale(0.95);
  }
  to {
    opacity: 1;
    transform: scale(1);
  }
}

/* =========================
   MOBILE MEDIA QUERY (<= 768px)
   ========================= */
   @media only screen and (max-width: 768px) {
  .popup-content {
    max-width: 90%;
    width: 90%;
    padding: 20px;
    padding-right: 20px;
    border-radius: 10px;
    box-shadow: none; /* If you want a simpler look on mobile */
  }

  h2 {
    padding: 0px;
    font-size: 15px;
    width: 100%;
    margin-bottom: 10px;
  }

  .popup-content .highlight {
    font-size: 16px;
    padding: 10px;
    margin-top: 5px;
  }

  .benefits {
    font-size: 14px;
    padding-left: 15px;
  }
  .benefits li {
    margin-bottom: 8px;
  }

  .how-it-works h3 {
    font-size: 18px;
  }
  .how-it-works ol {
    font-size: 14px;
  }

  .spots-limited {
    font-size: 14px;
  }

  .cta-button {
    font-size: 14px;
    padding: 6px 16px;
    margin-top: 15px;
    height: auto;
  }

  .form-container {
    gap: 10px;
  }
  .form-container input,
  .form-container textarea {
    font-size: 13px;
    padding: 8px;
  }
  .submit-button {
    font-size: 14px;
    padding: 8px;
  }
}
@media only screen and (max-width: 480px) {
  .cta-button {
    font-size: 11px !important;
  }
  h2 {
    font-size: 13px !important;
    line-height: 20px !important;
  }
}

</style>
