<template>
  <div class="progress-reward-box">
    <h3 v-html="title"></h3>
    <div class="reward-container">
      <div v-for="(offer, index) in offers" :key="offer.id" class="reward-card">
        <!-- Dynamically load image or fallback -->
        <div @click="openOfferDialog(offer)" style="cursor: pointer;">
          <img :src="offer.convertedImage || offer.image_url || require('../../assets/flag-icon-1000.png')"
               alt="Offer Image" class="reward-image"/>
          <p class="explore-text">Click to Explore</p>
        </div>

        <!-- Offer Value (Handles Overflow) -->
        <div class="offer-value-container">
          <p class="offer-value">-{{ offer.offer_value }}</p>
        </div>

        <button class="offer-value-button"
          @click="redeemOffer(offer.code || offer.offer_text, offer.merchant_homepage, offer.offer_description, offer.smart_link)" 
          :disabled="!canRedeem"
          :class="{ 'disabled-button': !canRedeem }"
        >
          Redeem for 1000 Mindchips
        </button>
      </div>

      <!-- Placeholder for missing offers -->
      <div v-for="index in Math.max(3 - offers.length)" :key="'placeholder-' + index" class="reward-card placeholder-card">
        <p class="placeholder-text">Select Your Product Category of Interest</p>
      </div>

      <!-- Always displayed third box -->
      <!-- <div class="reward-card hidden-brand-card">
        <img  @click="navigateToDashboard" style="margin-bottom: 4%; cursor: pointer;" src="../../assets/mindspeller-network.png" alt="Flag Icon" />
          <p class="offer-value" style="margin-bottom: 5%;">-?%</p>

        <button @click="redirectToCustomBrand">
          Position Your Favourite Hidden Brand
        </button>
      </div> -->
      <q-dialog v-model="showOfferDialog" class="no-overflow-dialog">
        <q-card style="max-width: 100%; width: 400px;">
          <q-card-section>
            <h4 style="word-wrap: break-word; text-align: center; font-weight: 600; padding: 10px;">Offer Details</h4>
            <p style="word-wrap: break-word; text-align: center; padding: 10px; line-height: 2vh;">
              {{ selectedOffer.offer_description }}
            </p>
            <p style="text-align: center;">
              <q-btn label="VISIT OFFER" color="blue" class="close-btn" @click="visitSmartLink = false" />
            </p>
          </q-card-section>
          <q-card-actions align="center" class="no-overflow-actions">
            <q-btn label="Close" color="red" class="close-btn" @click="showOfferDialog = false" />
          </q-card-actions>
        </q-card>
      </q-dialog>
       <!-- Q-Dialog for Redeem Code -->
    <q-dialog v-model="showDialog" class="no-overflow-dialog">
      <q-card style="max-width: 100%; width: 400px;">
        <q-card-section>
          <h4 style="word-wrap: break-word; text-align: center; font-weight: 600; padding: 10px;">Redeem Code</h4>
          <div class="row items-center q-gutter-sm">
            <q-input
              v-model="redeemCode"
              readonly
              filled
              class="col-grow"
            />
            <q-btn flat round icon="content_copy" @click="copyToClipboard(redeemCode)" />
          </div>
          <br>
          <p style="word-wrap: break-word; white-space: nowrap; font-size: 14px; text-align: center; white-space: normal;">{{description}}
          </p>
          <br>
          <p style="word-wrap: break-word; text-align: center;">
            <button 
              class="redeem-offer-btn" 
              @click="openSmartLink"
            >
              CLICK HERE TO REDEEM YOUR OFFER
            </button>
          </p>

        </q-card-section>
        <q-card-actions align="center" class="no-overflow-actions">
          <q-btn label="Close" color="red" class="close-btn" @click="showDialog = false" />
        </q-card-actions>
      </q-card>
    </q-dialog>
    </div>
  </div>
  <RedirectCasPopup v-if="showRedirectCas" @close="closeRedirectCas"></RedirectCasPopup>

</template>

<script>
import axios from 'axios';
import RedirectCasPopup from '@/components/respondent_panel/RedirectCasPopup.vue';
import { alert_error, genericDialog, toast_success } from '@/helpers/alert_helper.js';
export default {
  props: {
    title: String,
    friendsCount: Number,
    offers: {
      type: Array,
      default: () => [],
    },
  },
  components: { RedirectCasPopup },
  computed: {
    mindcoin_count() {
      return this.$store.state.mindchips;
    },
    canRedeem() {
       if(this.mindcoin_count > 1000 && this.friendsCount == 3) {
         return true;
       } else {
         return false;
       }
    },
  },
  mounted() {
    this.offers.forEach((offer, index) => {
      if (!offer.image_url.endsWith('.png')) {
        this.handleImageError(offer, index);
      }
    });
  },
  data() {
    return {
      showDialog: false,
      redeemCode: "",
      showRedirectCas: false,
      showOfferDialog: false,
      selectedOffer: {}
    };
  },
  methods: {
    openOfferDialog(offer) {
      this.selectedOffer = offer;
      this.showOfferDialog = true;
    },
    visitSmartLink() {
      if (this.selectedOffer.smart_link) {
        window.open(this.selectedOffer.smart_link, '_blank');
      }
    },
    navigateToDashboard() {
      this.$router.push('/dashboard');
    },
    openSmartLink() {
      if (this.smartLink) {
        window.open(this.smartLink, '_blank');
      }
    },
    redirectToCustomBrand() {
      this.showRedirectCas = true;
    },
    copyToClipboard(text) {
      navigator.clipboard.writeText(text).then(() => {
        toast_success('Copied to clipboard');
      }).catch(err => {
        alert_error('Failed to copy');
      });
    },
    convertToPng(imageUrl) {
      return new Promise((resolve, reject) => {
        const img = new Image();
        img.crossOrigin = 'Anonymous';
        img.onload = () => {
          const canvas = document.createElement('canvas');
          canvas.width = img.width;
          canvas.height = img.height;
          const ctx = canvas.getContext('2d');
          ctx.drawImage(img, 0, 0);
          const pngUrl = canvas.toDataURL('image/png');
          resolve(pngUrl);
        };
        img.onerror = () => reject(new Error(`Failed to load image: ${imageUrl}`));
        img.src = imageUrl;
      });
    },
    getFaviconUrl(websiteUrl) {
      try {
        const url = new URL(websiteUrl);
        return `${url.origin}/favicon.ico`;
      } catch (error) {
        console.error("Invalid website URL:", websiteUrl);
        return '/default-image.png'; // Use a fallback default image
      }
    },
    async loadImage(offer) {
      try {
        // Try to load the original image or convert to PNG if required
        const pngUrl = await this.convertToPng(offer.image_url); // Assuming convertToPng is defined elsewhere
        return pngUrl;
      } catch (error) {
        console.error(`Failed to load image: ${offer.image_url}`, error);

        // Fallback to favicon
        const faviconUrl = this.getFaviconUrl(offer.merchant_homepage);
        return faviconUrl;
      }
    },
    async handleImageError(offer, index) {
      try {
        const imageUrl = await this.loadImage(offer);
        this.offers[index].image = imageUrl; // Directly update the property in Vue 3
      } catch (error) {
        console.error(`Failed to load image for offer ${offer.code}`, error);
        this.offers[index].image = '/default-image.png'; // Use a default image as a fallback
      }
    },
    async redeemOffer(code, merchant_homepage, offer_description, smartLink) {
      const dialog_options = {
                title: 'Redeem Offer',
                text: `Are you sure you want to redeem this offer from '${merchant_homepage}' for 1000 Mindchips?`,
                confirmButtonText: this.$t("message.yes"),
                cancelButtonText: this.$t("message.no"),
            };
      const dialog_result = await genericDialog(dialog_options);
      if (dialog_result.isConfirmed) {
      try {
        // Call the API to reduce mindchips
        const response = await axios.post('/api/cas/reduce_mindchips', { amount: 1000 });
        if (response.data.success) {
          this.redeemCode = code;
          this.merchant_homepage = merchant_homepage;
          this.description = offer_description;
          this.smartLink = smartLink;
          this.showDialog = true;
          // Optionally, update the mindchips count in the store
          this.$store.dispatch('get_mindchip_balance');
        } else {
          console.error('Failed to reduce mindchips:', response.data.error);
        }
      } catch (error) {
        if (error.response.status === 422) {
            alert_error(this.$t('message.insufficient_credits_for_asset'));
        } else {
            alert_error(this.$t('message.general_server_error'));
        }     
      }
    }
    },
    redirectToCustomBrand() {
      this.showRedirectCas = true;
    },
    closeRedirectCas() {
      this.showRedirectCas = false;
    },
  },
};
</script>

<style scoped>


@media only screen and (max-width: 376px) {
  .progress-reward-box {
  display: flex;
  flex-direction: column;
  width: 100% !important;
  max-width: 560px;
  padding: 20px;
  background-color: #b1b1ec;
  border-radius: 15px 15px 15px 15px !important;
  text-align: center;
}
.reward-image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: contain;
  cursor: pointer;
}
}

@media only screen and (max-width: 480px) {
  .progress-reward-box h3 {
  background-color: blue;
  border-radius: 15px;
  color: white;
  font-size: 15px !important;
  padding: 10px;
  line-height:2.125rem ;
  text-align: center;
  margin-bottom: 10px;
 
}

.offer-value-button {
  max-width: 100%;
  height: fit-content;
  font-size: 8px;
}

.placeholder-text {
  font-size: 15px;
  color: #2e2d2d;
  text-align: center;
  margin: 4px !important;
  line-height: normal !important;
}
.progress-reward-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 20px;
  background-color: #b1b1ec;
  border-radius: 15px 15px 15px 15px !important;
  text-align: center;
  margin-top: 30px !important;
}

.reward-image {
  width: 50px;
  height: 50px;
  border-radius: 10px;
  object-fit: contain;
  cursor: pointer;
}
}

@media only screen and (max-width: 768px) {
  .progress-reward-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  margin-top: 10% !important;
  padding: 20px;
  background-color: #b1b1ec;
  border-radius: 15px 15px 15px 15px !important;
  text-align: center;

}
}

@media (min-width: 1440px) {

.reward-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 6px;
  flex: 0 0 auto; /* Do not shrink or grow */
  max-width: 150px !important;
  height: 200px;
  justify-content: space-evenly;

}
}
.progress-reward-box {
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 560px;
  padding: 20px;
  background-color: #b1b1ec;
  border-radius: 0 15px 15px 0;
  text-align: center;

}

/* Title (Heading) */
.progress-reward-box h3 {
  background-color: blue;
  border-radius: 15px;
  color: white;
  font-size: 20px;
  padding: 10px;
  line-height:2.125rem ;
  text-align: center;
  margin-bottom: 10px;
}

/* Reward Cards Container */
.reward-container {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 15px;
  margin-top: 5px;
  margin: 5px auto;
  /* flex-wrap: wrap; */
}

/* Reward Cards */
.reward-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  padding: 6px;
  flex: 0 0 auto; /* Do not shrink or grow */
  max-width: 120px;
  height: 200px;
  justify-content: space-evenly;
}

/* Offer Value Container (Prevents Overflow) */
.offer-value-container {
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-bottom: 5%;
  white-space: nowrap;
  display: flex;
  justify-content: center;
}

.offer-value-button {
  max-width: 100%;
  height: fit-content;
  font-size: 10px;
}

/* Offer Value */
.offer-value {
  font-size: 15px;
  font-weight: bold;
  color: black;
  margin: 5px;
  margin-bottom: 5%;
  text-align: center;
}

/* Placeholder Card */
.placeholder-card {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 12px;
  width: 100px;
  height: 190px;
  padding: 12px;
}

/* Hidden Brand Box */
.hidden-brand-card {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  border: 2px dashed #6c63ff;
  border-radius: 12px;
  padding: 12px;
  width: 120px;
  height: 190px;
  justify-content: center;
}

.hidden-brand-text {
  font-size: 12px;
  font-weight: bold;
  color: #6c63ff;
  text-align: center;
  margin-bottom: 5px;
}

/* Image Styling */
.reward-image {
  max-width: 80px;
  height: 80px;
  border-radius: 10px;
  object-fit: contain;
  cursor: pointer;
}

/* Click to Explore Text */
.explore-text {
  font-size: 8px;
  color: #777;
  text-align: center;
  margin: 0;
  padding: 0;
}

.redeem-offer-btn {
  background-color: #7878e9;
  color: white;
  border: none;
  padding: 10px 15px;
  border-radius: 10px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s ease;
  width: 100%;
  max-width: 300px;
  white-space: nowrap;
  margin: 0 auto;
  text-transform: uppercase;
  font-size: 14px;
}

.redeem-offer-btn:hover {
  background-color: #452c8c;
}

/* Redeem Button */
.reward-card button {
  background-color: #5940b3;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 10px;
  font-size: 10px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.reward-card button:hover {
  background-color: #452c8c;
}

/* Placeholder Text */
.placeholder-text {
  font-size: 15px;
  color: #2e2d2d;
  text-align: center;
  margin: 20px;
}

/* Redirect Button */
.redirect-button {
  background-color: #ff6347;
  color: white;
  border: none;
  padding: 8px;
  border-radius: 10px;
  font-size: 10px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.redirect-button:hover {
  background-color: #d9534f;
}
</style>
