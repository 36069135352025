<template>
  <div class="b2c-custom-sidebar">
    <loading-app-wide-search v-if="loading" />
    <!-- Enhanced Sidebar Title -->
    <div class="leftbar-title">
      {{ translations.my_values }}
    </div>

    <word-picker v-if="showWordSelector && !isCasNl" class="word-picker" @wordSelected="onWordSelected" :mobileScreen="mobileScreen" :openAssetPicker="openAssetPicker" @addAsset="onAddAsset" :selected_assets_and_positionings="selected_assets_and_positionings" :show_positionings="show_positionings" @removeAsset="onRemoveAsset">
    </word-picker>
    <word-picker-nl v-if="showWordSelector && this.currentlang === 'nl'" class="word-picker" @wordSelected="onWordSelected" :mobileScreen="mobileScreen" :openAssetPicker="openAssetPicker" @addAsset="onAddAsset" :selected_assets_and_positionings="selected_assets_and_positionings" :show_positionings="show_positionings" @removeAsset="onRemoveAsset">
    </word-picker-nl>
    <div
      v-if="showInstructionBubble"
      class="instruction-bubble"
    >
      <p style="font-size: 0.9em; white-space: normal; line-height: normal; ">{{ translations.select_value }}</p>
      <button style="text-align: center; justify-content: center; align-items: center;margin: 0 12%;" @click="hideBubble">OK</button>
    </div>
        <!-- <q-select dense outlined v-model="dummy" class=" form-select-sm" /> -->
    


    <asset-picker ref="asset-picker" :show_positionings="show_positionings" :allowed_types="allowed_types"
        @addAsset="onAddAsset">
    </asset-picker>


    <!-- Table Display Below Images -->
    <div class="collapsible-info-box">

    <div class="info-header" @click="toggleInfoBox">
      <font-awesome-icon :icon="['fas', 'file-invoice']" class="header-icon" />
      <span v-if="!collapsed_info">ACTIVE CONCEPT</span>
      <span v-if="collapsed_info" style="font-size: smaller; width: fit-content; text-align: center;">Active: {{ sidebarTitle }}</span>
      <font-awesome-icon :icon="collapsed_info ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" class="toggle-icon" />
    </div>
    <div v-show="!collapsed_info" class="sidebar-title-container">
      <div class="sidebar-title">
        <span>{{ sidebarTitle }}</span>
      </div>
    </div>


    <div v-show="!collapsed_info" class="image-box">
      <div v-if="imageUrl" class="image-container">
        <img :src="imageUrl" :alt="`Image` " class="image-display"  @click="$refs.fullscreen_preview.open" />
      </div>
    </div>


    

    <div v-show="!collapsed_info" class="info-content">
      <div v-if="tableData[0].value" class="info-item">
        <span class="label">MAPPED ON:</span>
        <span class="value">{{ tableData[0].value }}</span>
      </div>
      <div class="info-item">
        <span class="label">CONCEPT TYPE:</span>
        <span class="value">{{ tableData[1].value }}</span>
      </div>
      <!-- <div class="info-item">
        <span class="label">BRAND</span>
        <span v-if="tableData[2].value === '-' || tableData[2].value === '.'">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" class="disabled-icon" />
          <span class="website-link">-</span>
        </span>
        <a v-else :href="tableData[2].value" target="_blank" rel="noopener noreferrer" class="website-icon">
          <font-awesome-icon :icon="['fas', 'external-link-alt']" />
          <span class="website-link">{{ tableData[2].value }}</span>
        </a>
      </div> -->
      <p v-if="sortedBrandResults.length == 0" style="font-size: 10px; text-align: center;">Select your value above <br> to load more brands</p>
    </div>
  </div>

  
    
  <div v-if="wordData && sortedBrandResults.length" ref="associationsTable">
    <div class="brand-table">
      <div class="brand-title-container">
        <div class="brand-title">
          <span>{{ translations.explore_results }}</span>
        </div>
      </div>
      <div class="table-row custom-redirect-row">
        <span class="table-column">
          <button class="redirect-button" @click="goToCustomWebsite">
            {{ translations.add_results_brand }}
          </button>
        </span>
      </div>
      <!-- Display only the first 'visibleResultsCount' results -->
      <div
        class="table-row"
        v-for="(brand, index) in sortedBrandResults.slice(0, visibleResultsCount)"
        :key="index"
      >
        <span class="table-column">
          <img
            v-if="brand.favicon"
            :src="brand.favicon"
            alt="favicon"
            class="favicon-img"
            @error="handleFaviconError(index)"
          />
          <span v-else>No Favicon</span>
        </span>
        <span class="table-column" style="white-space: normal; text-align: left; justify-content:left;">{{ truncate(brand.name,30) }}</span>
        <a
          :href="brand.website"
          target="_blank"
          rel="noopener noreferrer"
          class="brand-website-icon"
        >
          <font-awesome-icon :icon="['fas', 'external-link-alt']" />
          <span class="website-link">{{ brand.website }}</span>
        </a>
      </div>
      
      <!-- Load More row -->
      <div
        v-if="visibleResultsCount < sortedBrandResults.length"
        class="table-row load-more-row"
        @click="openLoginPage"
      >
        <span class="table-column load-more-text"> {{ translations.load_more }}</span>
      </div>
    </div>
  </div>

  <div v-else-if="isEmptyResults" class="leftbar-title">
    {{ $t('new_asset.no_recommendations') }}
  </div>
    <!-- Instructions Section -->
    <!-- Collapsible Instructions Section -->
    <!-- <div class="instructions-box">
          <div class="instructions-header" @click="toggleInstructions">
            <font-awesome-icon :icon="['fas', 'info-circle']" class="header-icon" />
            <span>Instructions</span>
            <font-awesome-icon :icon="collapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" class="toggle-icon" />
          </div>
          <ul v-show="!collapsed" class="instructions-list">
            <li v-for="(instruction, index) in instructions" :key="index" class="instruction-item">
              <span>{{ instruction }}</span>
            </li>
          </ul>
        </div> -->
      <!-- Add exception text here -->
    </div>
  <full-screen-preview :wordData="wordData" ref="fullscreen_preview">
  </full-screen-preview>
  <B2CLoginPopup :searchBox="searchBox" ref="b2cLoginPopup"/>
</template>

<script>
import FullScreenPreview from "./FullScreenPreview.vue";
import WordPicker from '@/components/target_pickers/ias/WordPickerB2CDashboard.vue';
import WordPickerNl from '@/components/target_pickers/ias/WordPickerB2CDashboardNL.vue';
import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckboxDashboard.vue';
import LoadingAppWideSearch from '@/components/common/LoadingAppWideSearch.vue';
import B2CLoginPopup from './B2CLoginPopup.vue';
import AssetPicker from "@/components/asset_picker/AssetPicker.vue";
import { toast_success, alert_error } from '@/helpers/alert_helper.js';
import axios from 'axios';

export default {
  components: { FullScreenPreview, WordPicker, AssetCheckbox, LoadingAppWideSearch, AssetPicker, B2CLoginPopup, WordPickerNl },
  emits: ['assetAdded', 'assetRemoved'],
  props: {
    wordData: {
      type: Object,
      required: true,
      default: () => ({ asset_id: null, word_id: null, word: '', type: '-', website:'', lang: '' })
    },
    usageLimit: {
      type: Number,
      default: 0
    },
    mobileScreen: {
      type: Boolean,
      default: false
    },
    background: {
      required: false,
      type: String,
      default: 'img-icon.png',
    },
    show_positionings: { default: false, type: Boolean },
    allowed_types: { default: ['images', 'words', 'multimedia'], type: Array },
    showWordSelector: {
            default: true,
            type: Boolean
    },
    iaHint: {
        default: '',
        type: String
    },
    isLabelRemoved: {
        default: true,
        type: Boolean
    },
  },
  data() {
    return {
      instructions: [
        "First enter a product category from the network and choose a value from the left side bar.",
        "Explore the top brand recommendations generated by Mindspeller.",
        "Isolating the selected node(s) will create a sub-network. Click the dashboard icon to reset.",
      ],
      tableData: [],
      selectedWord: null,
      imageUrl: '',
      collapsed: true,
      collapsed_info: true,
      selected_assets_and_positionings: new Set(),
      dummy: null,
      visibleResultsCount: 5,
      first_selected_word: null,
      loading: false,
      sortedBrandResults: [],
      defaultEntry:[],
      isEmptyResults: false,
      showInstructionBubble: false,
      currentlang: '',
      restrictedDisplayNames: [
      'shutterstock_1284088828',
      '583571431',
      'shutterstock_1938550555',
      'shutterstock_635611256',
      '373369408',
      '1320625385',
    ],
    };
  },
  computed: {
    sidebarTitle() {
      return this.wordData && this.wordData.word ? this.wordData.word : "Node";
    },
    isRestrictedDisplayName() {
      return (
        this.first_selected_word &&
        this.restrictedDisplayNames.includes(this.first_selected_word.display_name)
      );
    },
    isCasNl() {
      return window.location.hostname.includes("cas-nl");
    },
    translations() {
      const isCasNl = this.isCasNl;
      return {
        explore_results: isCasNl ? 'RESULTATEN ONTDEKKEN' : 'EXPLORE RESULTS',
      add_results_brand:isCasNl ? "Voeg je merk hier toe" : "Add Your Brand Here",
      select_value: isCasNl ? 'Selecteer je waarde om verborgen merken te ontdekken!' : 'Select your value to discover hidden gem brands!',
      load_more: isCasNl ? 'Meer laden...' :'Load More...',
      my_values: isCasNl ? 'Mijn waarden' : 'My Values',

      };
    },
    // shouldProcessAssociations() {
    //   // Trigger processAssociations when both wordData and first_selected_word are set
    //   return this.wordData && this.wordData.word_id && this.first_selected_word && this.first_selected_word.word.word_id;
    // }
  },
  created() {
        this.currentlang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE') || 'nl';
    },
  watch: {
    showWordSelector(newVal) {

    },
    // shouldProcessAssociations: {
    //   handler(newValue) {
    //     if (newValue) {
    //       // Start processing once the conditions are met
    //       const wordDataWordId = this.wordData.word_id;
    //       const selectedWordId = this.first_selected_word.word.word_id;
    //       this.processAssociations(wordDataWordId, selectedWordId);
    //     }
    //   },
    //   immediate: true
    // },
    wordData: {
      handler(newWordData) {
        if(this.usageLimit == 1){
          setTimeout(() => {
          this.showInstructionBubble = true;
        },         
        1500);
        }
       

        // Check if image_url exists and update accordingly
        // this.collapsed_info = !newWordData || Object.keys(newWordData).length === 0;
        this.updateImage(newWordData && newWordData.image_url);
        // Update the table data based on the new wordData values
        this.updateTable();
        this.$nextTick(() => {
          if (this.$refs.associationsTable) {
            const formContainer = this.$refs.associationsTable;
            formContainer.scrollIntoView({ behavior: 'smooth' });
          }
        });
      },
      deep:true,
      immediate: true
    }
  },
  mounted() {
    // Initial fetch for tableData when component mounts
    this.updateTable();
  },
  methods: {
    goToCustomWebsite() {
      const routeData = this.$router.resolve({ name: 'RespondentSignup' });
      window.open(routeData.href, '_blank');
    },
    hideBubble() {
      this.showInstructionBubble = false;
    },
    openLoginPage(){
      this.$refs.b2cLoginPopup.openPopup();
    },
    toggleInfoBox() {
      this.collapsed_info = !this.collapsed_info;
    },
    handleFaviconError(index) {
      if (this.sortedBrandResults && this.sortedBrandResults[index]) {
        const favicon = this.sortedBrandResults[index].favicon;
        // If favicon is a data URL, leave it as is.
        if (favicon && favicon.startsWith("data:")) {
          console.log(favicon);
          return;
        }
        // If the URL is protocol-relative (starts with "//"), prepend "https:"
        if (favicon && favicon.startsWith("//")) {
          this.sortedBrandResults[index].favicon = `https:${favicon}`;
          return;
        }
        // Otherwise, use the fallback image.
        this.sortedBrandResults[index].favicon = require(`@/assets/${this.background}`);
      }
    },
    onWordSelected: async function (word) {
            this.showInstructionBubble = false;
            let word_object = Object.assign({}, word);
            word_object.display_name = word.word;
            word_object.word = { 'word_id': word.word_id }
            let combined_object = { 'asset': word_object, 'positioning': '' }
            if (this.selected_assets_and_positionings.size > 0) {
                // Skip adding if a word is already selected
                if (
                  this.first_selected_word &&
                  this.restrictedDisplayNames.includes(this.first_selected_word.display_name)
                ) {
                  alert(`Select only one value. Already chosen one.`);
                  return;
                }
                alert(`Select only one value. Already chosen: ${ this.first_selected_word.display_name }`);
                return;
            }
            
            this.selected_assets_and_positionings.add(combined_object);
            this.$emit('assetAdded', combined_object);
            const msg = this.$t("message.added", { words: combined_object.asset.display_name });
            toast_success(msg);

            this.first_selected_word = word_object;
            const wordData_word_id = this.wordData && this.wordData.word_id ? this.wordData.word_id : '-';
            if (wordData_word_id !== '-'){
              await this.processAssociations(wordData_word_id,this.first_selected_word.word.word_id);
            }
    },
    truncate(text, length) {
      if (text.length > length) {
        return text.substring(0, length) + '...';
      }
      return text;
    },
    reset: function () {
        this.selected_assets_and_positionings.clear();
    },
    onRemoveAsset: function (asset_and_positioning) {
            this.selected_assets_and_positionings.delete(asset_and_positioning);
            this.$emit('assetRemoved', asset_and_positioning);
            this.first_selected_word = [];
            this.sortedBrandResults = [this.defaultEntry];
            this.isEmptyResults = false;
        },
    openAssetPicker: function () {
        this.$refs["asset-picker"].openAssetPicker();
    },
    onAddAsset: async function (payload) {
        this.showInstructionBubble = false;
        if (this.selected_assets_and_positionings.size > 0) {
                // Skip adding if a word is already selected
                if (
                  this.first_selected_word &&
                  this.restrictedDisplayNames.includes(this.first_selected_word.display_name)
                ) {
                  alert(`Select only one value. Already chosen one.`);
                  return;
                }
                alert(`Select only one value. Already chosen: ${ this.first_selected_word.display_name }`);
                return;
          }
        this.selected_assets_and_positionings.add(payload);
        this.$emit('assetAdded', payload);
        const msg = `Added concept`;
        toast_success(msg);
        this.first_selected_word = payload.asset;
        // Check if wordData and first_selected_word are available, then call processAssociations
        const wordData_word_id = this.wordData && this.wordData.word_id ? this.wordData.word_id : '-';
        if (wordData_word_id !== '-') {
            const selectedWordId = payload.asset.word && payload.asset.word.word_id;
            if (selectedWordId) {
                await this.processAssociations(wordData_word_id, selectedWordId);
            }
        }
    },
    toggleInstructions() {
      this.collapsed = !this.collapsed;
    },
    updateTable() {
      // Set default values if `wordData.mapped` or `wordData.type` is undefined or null
      const mapped = this.wordData && this.wordData.mapped ? this.wordData.mapped : '';
      const type = this.wordData && this.wordData.type ? this.wordData.type : '-';
      const website = this.wordData && this.wordData.website ? this.wordData.website : '-';
      const lang = this.wordData && this.wordData.lang ? this.wordData.lang : '-';
      this.lang_env = lang;
      this.tableData = [
        { title: 'Mapped on', value: mapped },
        { title: 'Concept Type', value: type },
        { title: 'Website', value: website },
      ];
      this.sortedBrandResults = [];
      this.defaultEntry = {
      name: this.sidebarTitle,
      score: 100, // You can set a default score or any other value
      website: website,
      favicon: require(`@/assets/${this.background}`),
    };
    this.sortedBrandResults = [this.defaultEntry];
      if(this.first_selected_word){
        this.processAssociations(this.wordData.word_id, this.first_selected_word.word.word_id);
      }
      // this.onRemoveAsset(this.asset_and_positioning);
      // this.reset();
    },
    updateImage(image_url) {
      this.imageUrl = image_url ? image_url : '';
    },
    loadMoreResults() {
      this.visibleResultsCount += 5;
    },
    async processAssociations(product, value) {
    this.visibleResultsCount = 6;
    const payload = {
      asset_1: product,
      asset_2: value,
      category: this.sidebarTitle,
    };
    this.loading = true;
    try {
      const response = await axios.post("/projector/analyze", payload);
      this.loading = false;
      const data = response && response.data;
      const brandResults = data && data.brand_results;
      
      // Always include the default entry's website, giving it top priority
      const uniqueWebsites = new Set();
      if (this.defaultEntry && this.defaultEntry.website) {
        uniqueWebsites.add(this.defaultEntry.website.toLowerCase());
      }

      if (brandResults && Object.keys(brandResults).length > 0) {
        const results = Object.entries(brandResults)
          .map(([name, x]) => ({
            name: x.title && x.title !== "No title found" ? x.title : name,
            score: x.score,
            website:
              x.website.startsWith("https://") || x.website.startsWith("http://")
                ? x.website
                : `https://${x.website}`,
            favicon: x.favicon || require(`@/assets/${this.background}`),
          }))
          .filter(result => {
            if (
              result.website !== "No website found" &&
              !uniqueWebsites.has(result.website.toLowerCase())
            ) {
              uniqueWebsites.add(result.website.toLowerCase());
              return true;
            }
            return false;
          })
          .sort((a, b) => b.score - a.score);
        this.sortedBrandResults = [this.defaultEntry, ...results];
        this.isEmptyResults = false;
        this.$nextTick(() => {
            if (this.$refs.associationsTable) {
              const formContainer = this.$refs.associationsTable;
              formContainer.scrollIntoView({ behavior: 'smooth' });
            }
          });
      } else {
        this.sortedBrandResults = [this.defaultEntry];
        this.isEmptyResults = true;
      }
      this.hasProcessed = true;
      return response;
      } catch (e) {
        if (e.response.status === 403) {
          alert_error(this.$t('message.no_access_to_items'));
        } else {
          alert_error(this.$t('message.general_server_error'));
        }      
      } finally {
        this.loading = false;
      }
    }
  }
};
</script>

<style scoped>
.leftbar-title{
  font-family: 'Roboto', sans-serif;
  font-size: 1.2em;
  color: #333;
  text-align: center;
  font-weight: bold;
  padding: 8px 10px;
  /* margin-top: 2px;  */
  margin-left: auto; 
  margin-right: auto;
  margin-bottom: 10px;
  z-index: 4;
}
.b2c-custom-sidebar {
  position: fixed;
  top: 22%;
  left: 0;
  max-width: 22%;
  height: 70%;
  padding: 20px;
  /* z-index: 9999; */
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #7878e9 transparent;
  /* display: flex;
  flex-direction: column;
  justify-content: space-between; */
}



.exception-text {
  font-size: 1rem;
  color: #7878e9;
  text-align: justify; /* Justify the text */
  font-weight: bold;
  padding: 10px;
  border-top: 1px solid #ccc;
  margin: 20px auto;
}

.warning-icon {
  color: red;
  font-size: 1em;
  margin-right: 10px;
  vertical-align: middle;
}

/* Custom scrollbar styling for Webkit browsers (Chrome, Safari) */
.b2c-custom-sidebar::-webkit-scrollbar {
  width: 6px;
}

.b2c-custom-sidebar::-webkit-scrollbar-track {
  background: transparent;
}

.b2c-custom-sidebar::-webkit-scrollbar-thumb {
  background-color: #7878e9;
  border-radius: 4px;
}

.b2c-custom-sidebar::-webkit-scrollbar-thumb:hover {
  background-color: #5a5dbb;
}

/* Sidebar Title */
.sidebar-title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.sidebar-title {
  background-color: transparent;
  border: 2px solid #7878e9;
  color: black;
  padding: 5px 0;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  margin-top: 10%;
  overflow-wrap: break-word;
  white-space: normal;
}

/* Info Box */
.info-box {
  background: #fff;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  align-items: center;
  margin-top: 20px;
  width: 95%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  margin-left: auto;
  margin-right: auto;
}

.info-item {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  margin-bottom: 10px;
}

.label, .value {
  color: #333;
  margin-right: 5px;
}

.label {
  font-weight: bold;
  text-align: center;
}

.value {
  font-weight: normal;
  text-align: center;
}

/* Image Box */
.image-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;
}

.image-container {
  margin: 10px 0;
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-display {
  width: 100%;
  max-width: 200px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease;
  z-index: 1;
}

.image-display:hover {
  transform: scale(1.2);
}

/* Instructions Box */
.instructions-box, .collapsible-info-box {
  background-color: #f9fbfd;
  border: 1px solid #7878e9;
  border-radius: 8px;
  padding: 5px;
  margin-top: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  max-width: 100%;
  /* min-width: 75%; */
  transition: max-height 0.3s ease;
  margin: 10px auto;
}

.instructions-header, .info-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 10px 15px; */
  font-size: 1em;
  font-weight: bold;
  border: 1px solid #eef3fa;
  border-radius: 8px;
  color: #333;
  cursor: pointer;
  margin-left: auto; margin-right: auto;
}

.instructions-header:hover, .info-header:hover {
  background-color: #a2c9ff;
}

.header-icon, .info-icon, .bullet-icon, .toggle-icon {
  color: #000000;
  transition: transform 0.3s;
}

.instructions-list, .info-content {
  padding: 15px;
}

.instructions-list {
  list-style: none;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.instruction-item {
  display: flex;
  align-items: center;
  background-color: #eef3fa;
  border-radius: 5px;
  padding: 10px;
  transition: background 0.3s;
}

.instruction-item:hover {
  background-color: #e2ebf6;
}

.instruction-item span {
  font-size: 1em;
  color: #333;
}

/* Website Icon Tooltip */
.website-icon {
  color: #7878e9;
  font-size: 1em;
  display: inline-flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}

.website-link {
  visibility: hidden;
  opacity: 0;
  background-color: #333;
  color: #fff;
  text-align: left;
  padding: 5px 10px;
  border-radius: 5px;
  font-size: 0.5em;
  position: absolute;
  z-index: 9001;
  /* top: 90%; */
  right: 10%;
  white-space: normal;
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  width: fit-content;
  max-width: 150px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  transition: opacity 0.3s ease;
}

.website-icon:hover .website-link {
  visibility: visible;
  opacity: 1;
}

.brand-website-icon:hover .website-link {
  visibility: visible;
  opacity: 1;
}

/* Disabled Icon */
.disabled-icon {
  color: #ccc;
  cursor: default;
}

/* Brand table */
.brand-title-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
}

.brand-title {
  background-color: transparent;
  color: black;
  padding: 5px 0;
  border-radius: 8px;
  font-size: 1em;
  font-weight: bold;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  width: 95%;
  margin-top: 10%;
  overflow-wrap: break-word;
  white-space: normal;
}

.brand-table {
  max-width: 100%; /* Ensures the table fits the container */
  margin-top: 20px;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow-y: hidden;
  z-index: 1;
}

.table-header, .table-row {
  display: grid; /* Grid layout ensures consistent column alignment */
  grid-template-columns: 1fr 2fr 0.5fr; /* Adjust column widths */
  align-items: center;
  padding: 10px 20px;
  background-color: #f9fbfd;
  text-align: left;
}

.table-header {
  font-weight: bold;
  color: #333;
  border-bottom: 1px solid #ccc;
}

.custom-redirect-row {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fafafa;
  cursor: pointer;
}

.redirect-button {
  background-color: #7878e9;
  color: #ffffff;
  border: none;
  border-radius: 5px;
  font-weight: bold;
  padding: 10px 10px;
  transition: background-color 0.2s ease-in-out;
}

.redirect-button:hover {
  background-color: #5940b3;
}

.favicon-img {
  width: 20px;
  height: 20px;
  margin-right: 10px;
  object-fit: contain;
  vertical-align: middle;
}

.brand-website-icon {
  color: #7878e9;
  font-size: 1em;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.table-row:nth-child(even) {
  background-color: #b1b5ba;
}

.table-column {
  display: flex;
  align-items: center; /* Ensures vertical alignment */
  gap: 10px; /* Spacing between elements */
  overflow-wrap: break-word;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.table-column:first-child {
  justify-content: flex-start; /* Align the first column to the left */
}

.table-column:last-child {
  justify-content: flex-end; /* Align the last column to the right */
}

.brand-name {
  text-align: right; /* Ensure text in the last column is aligned properly */
  margin-left: auto; /* Push the text to the right */
  font-weight: bold;
}

/* Load More Row Styles */
.load-more-row {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 15px;
  cursor: pointer;
  background-color: #fafafa;
  overflow: hidden;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.load-more-row:hover {
  background-color: #e8e8e8;
  /* transform: scale(1.02); */
}

.load-more-text {
  font-weight: bold;
  color: #6c63ff;
}

.instruction-bubble {
  display: inline-block !important;
  background: rgba(142, 142, 241, 0.5); /* Semi-transparent background */
  color: black;
  font-size: 0.9em;
  font-weight: bold;
  line-height: normal;
  width: 100%;
  padding: 5px 10px;
  height: fit-content;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  margin: 5% auto;
  position: relative;
}

/* Upward-facing arrow */
.instruction-bubble::after {
  content: "";
  position: absolute;
  line-height: normal;
  bottom: 100%; /* Moves arrow above the box */
  /* left: 50%; */
  /* transform: translateX(-50%); */
  border-width: 0 8px 8px 8px; /* Creates an upward-facing triangle */
  border-style: solid;
  border-color: transparent transparent rgba(142, 142, 241, 0.5) transparent; /* Matches background */
}


/* Media Queries */
@media screen and (min-width: 1600px) {
  /* .b2c-custom-sidebar { top: 12.5%; height: calc(100% - 100px); width: 17%; padding: 25px; bottom: 0%; } */
  /* .sidebar-title { font-size: 1.1em;}
  .image-display { max-width: 230px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 1.1em; width: 105%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 1em; }
  .exception-text-container {margin-top: 150%;}
  .brand-table { width: 105%; } */
}

@media screen and (min-width: 1440px) and (max-width: 1599px) {
  /* .b2c-custom-sidebar { top: 20%; height: calc(100% - 150px); width: 20%; padding: 20px; } */
  /* .sidebar-title { font-size: 1em; }
  .image-display { max-width: 200px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 1em; width: 105%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.9em; }
  .brand-table { width: 105%; }
  .exception-text-container {margin-top: 75%;} */
}

@media screen and (max-width: 1439px) and (min-width: 1024px) {
  /* .b2c-custom-sidebar { top: 19%; height: calc(100% - 140px); width: 22%; padding: 15px; } */
  /* .sidebar-title { font-size: 0.95em; }
  .image-display { max-width: 200px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.9em; width: 112%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.9em; }
  .brand-table { width: 112%; }
  .exception-text-container {margin-top: 20%;} */
}

@media screen and (max-width: 1023px) and (min-width: 768px) {
  /* .b2c-custom-sidebar { top: 24%; height: calc(100% - 140px); width: 28%; padding: 10px; } */
  /* .sidebar-title { font-size: 0.9em; }
  .image-display { max-width: 180px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.85em;  width: 115%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.85em; }
  .brand-table { width: 115%; } */
}

@media screen and (max-width: 767px) and (min-width: 480px) {
  /* .b2c-custom-sidebar { top: 27%; height: calc(100% - 120px); width: 100%; padding: 10px; position: relative; } */
  /* .sidebar-title { font-size: 0.85em; }
  .image-display { max-width: 150px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.8em;  width: 115%; margin-left: auto; margin-right: auto;}
  .instruction-item span, .table-column { font-size: 0.8em; }
  .brand-table { width: 100%; } */
}

@media screen and (max-width: 479px) {
  /* .b2c-custom-sidebar { top: 30%; height: calc(100% - 100px); width: 100%; padding: 5px; position: relative; } */
  /* .sidebar-title { font-size: 0.8em;}
  .image-display { max-width: 120px; }
  .info-box, .instructions-box, .collapsible-info-box { font-size: 0.75em;  margin-left: auto; margin-right: auto;}
  .instruction-item { padding: 5px; }
  .instruction-item span, .table-column { font-size: 0.75em; }
  .brand-table { width: 100%; } */
}

@media only screen and (max-width: 480px) {
  .b2c-custom-sidebar {
    padding: 10px 10px !important;
    top: auto;
    bottom: 0;
    height: 30% !important;
    overflow-y: auto;
    position: static !important; 
    -webkit-overflow-scrolling: touch;
    padding-bottom: env(safe-area-inset-bottom);
  }
  .redirect-button {
    padding: 4px !important;
  }
}

@media only screen and (max-width: 768px) {
  /* Ensure the sidebar remains visible beneath the header */
  .b2c-custom-sidebar {
    position: static !important;
    background-color: #fff;
    /* Adjust this value to match your top header height */
    top: auto;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    /* Fill the remaining screen height below the header */
    height: 30% !important;
    margin: 0;
    padding: 10px;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    padding-bottom: env(safe-area-inset-bottom);
    /* z-index: 997; */
  }

  .leftbar-title {
    font-size: 1em;
    padding: 6px 8px;
    margin-bottom: 8px;
  }

  .sidebar-title,
  .brand-title {
    font-size: 0.9em;
    margin-top: 5%;
    border-width: 1px; /* Slightly thinner border for mobile */
  }

  /* Images */
  .image-display {
    max-width: 150px; /* Reduce image size on mobile */
  }

  /* Collapsible box headers */
  .info-header,
  .instructions-header {
    font-size: 0.9em;
    padding: 8px;
  }

  /* Info & instruction content areas */
  .info-content,
  .instructions-list {
    padding: 10px;
  }
  .instruction-item {
    padding: 8px;
    font-size: 0.9em;
  }
  .info-item {
    font-size: 0.9em;
  }

  /* Brand table: fewer columns, wrapping text */
  .table-header,
  .table-row {
    grid-template-columns: 1fr 1fr 1fr; /* Two columns on mobile */
    padding: 5px 10px;
  }
  .favicon-img {
    width: 16px;
    height: 16px;
  }
  .table-column {
    white-space: normal; /* Allow wrapping on narrow screens */
  }
  .load-more-row {
    padding: 10px;
  }
  .website-link {
    font-size: 0.7em;
    max-width: 100px; /* Constrain link width further on mobile */
  }

  /* Instruction bubble */
  .instruction-bubble {
    width: 100%;
    margin: 5% auto auto auto; /* Center on mobile */
    font-size: 0.8em;
  }
}

</style>


