<template>
  <div class="container-fluid" style="position: relative;">
    <div class="row">
      <div class="col-md-8">
        <div v-if="isMobile" class="mainpage-header-container" ref="verificationMessage">
            <h2>
              <img src="../../assets/mindchips_black.png" alt="" class="coins-logo"/>
              <bold>Mindchips: {{ mindcoin_count }}</bold>
            </h2>
          </div>
        <div class="scroll">
          <!-- <page-title title="Rewards" :desc="this.$t('message.rp')"></page-title> -->

          <div class="mainpage-header-container" ref="verificationMessage">
            <h2>
              <bold>{{ $t("message.respondentpanel_title") }}</bold>
            </h2>
          </div>

          <div
            v-if="showVerificationMessage && !this.phoneverification"
            @click="redirectToMyAccount"
            class="verify-container"
          >
            <h2>
              <font-awesome-icon
                :icon="['fas', 'warning']"
                style="color: red;"
              />
              {{ $t("message.respondentpanel_phoneverification") }}
            </h2>
          </div>
          <!-- Progress Section -->
          <div class="progress-box">
            <div class="progress-box-header">
              <h2>
                <font-awesome-icon :icon="['fas', mindcoinIcon]" />
                {{ $t("message.respondentpanel_title2") }}
                <font-awesome-icon :icon="['fas', mindcoinIcon]" />
              </h2>
            </div>
            <MindchipProgress :currentChips="mindcoin_count" />
            <h2 class="h2">
              {{ $t("message.respondentpanel_title3") }}
            </h2>
            <div class="progress-grid">
              <Milestone
                :description="
                  $t('message.respondentpanel_milestone1_description')
                "
                :category="selectedCategory"
              >
                <template #title>
                  HIT YOUR 1<SUP>st</SUP> MILESTONE
                  <img
                    src="../../assets/flag-icon-1000.png"
                    alt="Flag Icon"
                    style="width: 35px; height: 35px; margin-right: 2%;"
                  />
                </template>
                <template #button1>
                  <button
                    style="border-radius: 15px; width: 100%; padding: 5px; margin: 5px 0;"
                    @click="launchInviteThreeFriends"
                  >
                    Invite {{ 3 - friendsCount }} Friends
                    <font-awesome-icon :icon="['fas', 'circle-plus']" />
                  </button>
                </template>
                <!-- Dynamic Category Dropdown -->
                <select
                  v-model="selectedCategory"
                  class="category-dropdown"
                  @change="fetchCoupons"
                >
                  <option value="" disabled>{{
                    $t("message.respondentpanel_select_category")
                  }}</option>
                  <option
                    v-for="category in categories"
                    :key="category"
                    :value="category"
                  >
                    {{ category }}
                  </option>
                </select>
              </Milestone>

              <RewardCard
                :title="$t('message.respondentpanel_redeem_coupon')"
                :offers="filteredOffers"
                :friendsCount="friendsCount"
                cost="1000"
              />
              <Milestone10000
                title="HIT YOUR 2<SUP>nd</SUP> MILESTONE"
                :description="
                  $t('message.respondentpanel_milestone2_description')
                "
                :category="selectedPreferredCategory"
                :friendsCount="friendsCount"
                :currentChips="mindcoin_count"
              >
                <template #title>
                  HIT YOUR 2<SUP>nd</SUP> MILESTONE
                  <img
                    src="../../assets/flag-icon-10000.png"
                    alt="Flag Icon"
                    style="width: 45px; height: 45px; margin-right: 2%;"
                  />
                </template>
                <template #dropdown>
                  <select
                    id="preferred-category"
                    v-model="selectedPreferredCategory"
                    @change="updatePreferredCategory"
                    class="preferred-category-dropdown"
                  >
                    <option value="" disabled>Select a Category</option>
                    <option value="electronics">Electronics</option>
                    <option value="fashion">Fashion</option>
                    <option value="food">Food</option>
                    <option value="travel">Travel</option>
                  </select>
                </template>
                <template #button1>
                  <button
                    v-if="friendsCount >= 3"
                    style="border-radius: 15px; width: 100%; padding: 5px; margin: 5px 0;"
                    @click="launchInviteFriends"
                  >
                    Invite {{ 13 - friendsCount }} Friends
                    <font-awesome-icon :icon="['fas', 'circle-plus']" />
                  </button>
                  <button
                    v-else
                    style="border-radius: 15px; width: 100%; padding: 5px; margin: 5px 0;"
                    @click="launchInviteFriends"
                  >
                    Invite 10 Friends <font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    />
                  </button>
                </template>
                <template #button2>
                  <!-- <button
                    style="border-radius: 15px; width: 100%; padding: 5px; margin: 5px 0;"
                    @click="anotherAction"
                  >
                    Another Action <br /><font-awesome-icon
                      :icon="['fas', 'circle-plus']"
                    />
                  </button> -->
                </template>
              </Milestone10000>

              <MaximizeRewardCard
                :title="$t('message.respondentpanel_score_discounts')"
                :images="brandImages"
                @launchPro="launchBecomeMindspeller"
              />
            </div>
          </div>
        </div>
      </div>
      <div class="col-md-4">
        <div class="reward-section">
          <div class="reward-container">
            <div class="">
              <div class="rightBar">
                <!-- class="rightBar" -->
                <div class="tasks-action-buttons">
                  <div class="button-wrapper">
                    <button
                      @click="launchBecomeMindspeller"
                      class="pro-button"
                      style="z-index: auto;"
                    >
                      {{ $t("message.respondentpanel_CTA1")
                      }}<img
                        src="../../assets/macrotellectheadset.png"
                        alt="Step 1 Image"
                        style="max-width: 65px; max-height: 60px; margin: auto;"
                      />
                    </button>
                  </div>
                  <div class="button-wrapper">
                    <button
                      @click="handleButtonClick('launchBrandGame')"
                      class="task-action-button"
                      style="z-index: auto; "
                      :disabled="showVerificationMessage"
                    >
                      {{ $t("message.respondentpanel_CTA2") }}<br /><small
                        ><small>(1 Mindchip per thought)</small></small
                      >
                    </button>
                  </div>
                  <div class="button-wrapper">
                    <button
                      @click="handleButtonClick('launchShareThoughts')"
                      class="task-action-button"
                      style="z-index: auto;"
                      :disabled="showVerificationMessage"
                    >
                      {{ $t("message.respondentpanel_CTA3") }}<br /><small
                        ><small>(2 Mindchips per thought)</small></small
                      >
                    </button>
                  </div>
                  <div class="button-wrapper">
                    <button
                      @click="launchClinicalTrials"
                      class="clinicaltrial-button"
                      style="z-index: auto;"
                    >
                      {{ $t("message.respondentpanel_CTA4")
                      }}<img
                        src="../../assets/mindspeakerheadset.png"
                        alt="Step 1 Image"
                        style="max-width: 65px; max-height: 60px; margin: auto;"
                      />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- Actions Section -->
  <!-- <div class="reward-section">
    <div class="reward-container">
      <div class="rightbox-sec">
        <div class="rightBar ">
          <div class="tasks-action-buttons">
            <div class="button-wrapper">
              <button
                @click="launchBecomeMindspeller"
                class="pro-button"
                style="z-index: auto;"
              >
                {{ $t("message.respondentpanel_CTA1")
                }}<img
                  src="../../assets/macrotellectheadset.png"
                  alt="Step 1 Image"
                  style="max-width: 65px; max-height: 60px; margin: auto;"
                />
              </button>
            </div>
            <div class="button-wrapper">
              <button
                @click="handleButtonClick('launchBrandGame')"
                class="task-action-button"
                style="z-index: auto; "
                :disabled="showVerificationMessage"
              >
                {{ $t("message.respondentpanel_CTA2") }}<br /><small
                  ><small>(1 Mindchip per thought)</small></small
                >
              </button>
            </div>
            <div class="button-wrapper">
              <button
                @click="handleButtonClick('launchShareThoughts')"
                class="task-action-button"
                style="z-index: auto;"
                :disabled="showVerificationMessage"
              >
                {{ $t("message.respondentpanel_CTA3") }}<br /><small
                  ><small>(2 Mindchips per thought)</small></small
                >
              </button>
            </div>
            <div class="button-wrapper">
              <button
                @click="launchClinicalTrials"
                class="clinicaltrial-button"
                style="z-index: auto;"
              >
                {{ $t("message.respondentpanel_CTA4")
                }}<img
                  src="../../assets/mindspeakerheadset.png"
                  alt="Step 1 Image"
                  style="max-width: 65px; max-height: 60px; margin: auto;"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div> -->
  <CasBrandGame
    v-if="showCasBrandGame"
    ref="casBrandGame"
    @brandGameOver="brandGameOverStatus"
    :showCasBrandGame="this.showCasBrandGame"
    @close="closeBrandGame"
  />
  <InviteFriends
    v-if="showInviteFriends"
    ref="inviteFriends"
    @close="closeInviteFriends"
    :showInviteFriends="this.showInviteFriends"
    :inviteCount="this.inviteCount"
    :friends="this.friends"
  />
  <ShareThoughtsSurveyPopup
    v-if="showSurveyPopup"
    ref="shareThoughts"
    @close="closeShareThoughts"
    @survey-submitted="surveySubmitted"
    :showSurveyPopup="this.showSurveyPopup"
  />

  <BecomeMindspeller
    @close="closeBecomeMindspeller"
    v-if="showBecomeMindspeller"
  />
  <ClinicalTrial @close="closeClinicalTrials" v-if="showClinicalTrials" />
</template>

<script>
import axios from "axios";
import WorkspaceEmpty from "@/components/common/WorkspaceEmpty.vue";
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";
import PageTitle from "../common/PageTitle.vue";
import MindchipProgress from "./MindchipProgress.vue";
import RewardCard from "./RewardCard.vue";
import MaximizeRewardCard from "./MaximizeRewardCard.vue";
import Milestone from "./Milestone.vue";
import Milestone10000 from "./Milestone10000.vue";
import BecomeMindspeller from "./BecomeMindspeller.vue";
import CasBrandGame from "./CasBrandGame.vue";
import InviteFriends from "./InviteFriends.vue";
import ShareThoughtsSurveyPopup from "./ShareThoughtsSurveyPopup.vue";
import ClinicalTrial from "./ClinicalTrial.vue";

import {
  toast_success,
  genericDialog,
  alert_error,
} from "@/helpers/alert_helper.js";

import { v4 as uuidv4 } from "uuid";

export default {
  components: {
    WorkspaceEmpty,
    PageTitle,
    LoadingAppWide,
    MindchipProgress,
    RewardCard,
    MaximizeRewardCard,
    Milestone,
    Milestone10000,
    BecomeMindspeller,
    CasBrandGame,
    InviteFriends,
    ShareThoughtsSurveyPopup,
    ClinicalTrial,
  },
  emits: ["start-game", "share-thoughts"],
  data: function() {
    return {
      loading: false,
      showCasBrandGame: false,
      showSurveyPopup: false,
      showBecomeMindspeller: false,
      showClinicalTrials: false,
      showInviteFriends: false,
      selectedCategory: "",
      selectedPreferredCategory: "",
      phoneverification: false,
      showVerificationMessage: false,
      categories: [],
      friends: [],
      preferred_categories: [],
      friendsCount: 0,
      filteredOffers: [],
      brandImages: [],
      inviteCount: 0,
      apiKey: "15a12f2df4285902672c1087002b3602",
      isMobile: false,
    };
  },
  computed: {
    mindcoin_count() {
      return this.$store.state.mindchips;
    },
    mindcoinIcon() {
      return this.currentLang === "nl" ? "euro" : "dollar";
    },
  },
  mounted() {
    // Fetch categories on component mount
    this.fetchCategories();
    this.fetchPreferredCategory();
    this.fetchFriends();
    this.fetchPhoneVerificationStatus();
    if (window.innerWidth <= 768) {
      this.isMobile = true;
    }
  },
  methods: {
    redirectToMyAccount: function() {
      this.$router.push({ name: "My Account" });
    },
    handleButtonClick(action) {
      if (!this.phoneverification) {
        this.showVerificationMessage = true;
      } else {
        this[action]();
      }
    },
    launchBrandGame() {
      this.showCasBrandGame = true;
      this.showSurveyPopup = false;
      this.showBecomeMindspeller = false;
      this.showClinicalTrials = false;
    },
    closeBrandGame() {
      this.showCasBrandGame = false;
    },
    launchShareThoughts() {
      this.showSurveyPopup = true;
      this.showCasBrandGame = false;
      this.showBecomeMindspeller = false;
      this.showClinicalTrials = false;
    },
    closeShareThoughts() {
      this.showSurveyPopup = false;
    },
    launchBecomeMindspeller() {
      this.showBecomeMindspeller = true;
      this.showCasBrandGame = false;
      this.showSurveyPopup = false;
      this.showClinicalTrials = false;
    },
    closeBecomeMindspeller() {
      this.showBecomeMindspeller = false;
    },
    launchClinicalTrials() {
      this.showBecomeMindspeller = false;
      this.showClinicalTrials = true;
      this.showCasBrandGame = false;
      this.showSurveyPopup = false;
    },
    closeClinicalTrials() {
      this.showClinicalTrials = false;
    },
    launchInviteFriends() {
      this.showInviteFriends = true;
      this.inviteCount = 10;
    },
    launchInviteThreeFriends() {
      this.showInviteFriends = true;
      this.inviteCount = 3;
    },
    closeInviteFriends() {
      this.showInviteFriends = false;
    },
    friendsFetched(friends) {
      this.friendsCount = friends.length;
    },
    async fetchFriends() {
      try {
        const response = await axios.get("/api/cas/respondents/list_by_org");
        this.friends = response.data.map((friend) => ({
          email: friend.email,
          current_user_invites: friend.current_user_invites,
          step1: friend.step1Completed,
          step2: friend.step2Completed,
          step3: friend.step3Completed,
        }));
        this.friendsCount = this.friends.length || 0;
      } catch (error) {
        this.friendsCount = 0;
        console.error("Error fetching friends:", error);
      }
    },
    async fetchCategories(retries = 3) {
      while (retries > 0) {
        try {
          const response = await axios.get("/api/cas/getCategories");
          if (response.data && response.data.categories) {
            this.categories = response.data.categories;
            return; // Exit the loop if successful
          } else {
            console.error("No categories available.");
          }
        } catch (error) {
          console.error("Error fetching categories:", error);
          retries -= 1;
        }
      }
    },
    async fetchCoupons() {
      if (!this.selectedCategory) {
        this.filteredOffers = [];
        return;
      }

      try {
        const response = await axios.get(`/api/cas/getOffersByCategory`, {
          params: { category: this.selectedCategory },
        });

        if (
          response.data &&
          response.data.offers &&
          response.data.offers.length > 2
        ) {
          const filteredOffers = response.data.offers;

          // Take the top three offers
          this.filteredOffers = filteredOffers.slice(0, 3);
        } else {
          console.error("No offers available for the selected category.");
          this.filteredOffers = response.data.offers;
        }
      } catch (error) {
        console.error("Error fetching offers:", error);
      }
    },
    async fetchPreferredCategory() {
      try {
        const response = await axios.get("/api/cas/preferred_category");
        if (response.data && response.data.preferred_category) {
          this.selectedPreferredCategory = response.data.preferred_category;
          this.brandImages = response.data.brands
            .map((brand) => brand.image_url)
            .slice(0, 2);
        } else {
          return;
        }
      } catch (error) {
        console.error("Error fetching preferred category:", error);
      }
    },
    async fetchPhoneVerificationStatus() {
      try {
        const response = await axios.get("/api/cas/phoneverification");
        if (response.data && response.data.phone) {
          this.phoneverification = true;
        } else {
          this.phoneverification = false;
          return;
        }
      } catch (error) {
        console.error("Error fetching preferred category:", error);
      }
    },
    async updatePreferredCategory() {
      try {
        const response = await axios.post(
          "/api/cas/update_preferred_category",
          {
            preferred_category: this.selectedPreferredCategory,
          }
        );
        if (response.data) {
          this.fetchPreferredCategory();
        } else {
          console.error(
            "Failed to update preferred category:",
            response.data.error
          );
        }
      } catch (error) {
        console.error("Error updating preferred category:", error);
      }
    },
  },
  watch: {
    showVerificationMessage(newVal) {
      if (newVal && !this.phoneverification) {
        this.$nextTick(() => {
          const verificationMessage = this.$refs.verificationMessage;
          if (verificationMessage) {
            verificationMessage.scrollIntoView({ behavior: "smooth" });
          }
        });
      }
    },
    selectedPreferredCategory(newCategory) {
      if (newCategory) {
        this.updatePreferredCategory();
      }
    },
  },
};
</script>

<style lang="scss" scoped>
// @media only screen and (max-width: 376px) {
//   .progress-grid {
//     display: grid !important;
//     // grid-template-columns: repeat(2, 1fr);
//     // gap: 10px;
//     margin: 5px auto;
//   }
// }

@media only screen and (max-width: 480px) {
  .progress-grid {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    // gap: 10px;
    margin: 5px auto;
  }
  .h2 {
    font-size: 15px;
    text-align: center;
    line-height: 30px !important;
  }
  .progress-box-header h2 {
    flex-grow: 1;
    font-size: 16px !important;
    text-align: center;
    color: white;
    margin: 0;
    line-height: 30px !important;
    padding: 10px;
  }
  .mainpage-header-container h2 {
    flex-grow: 1;
    font-size: 100% !important;
    text-justify: auto;
    text-align: center;
    font-weight: bold;
    text-align: center;
    margin: 0;
    line-height: 25px;
  }
  p {
    margin: 0px !important;
    text-align: justify;
    line-height: normal !important;
  }
  .category-dropdown {
    width: 100%;
    margin: 5px 0;
    margin-bottom: 0px !important;
    padding: 5px !important;
    font-size: 8px !important;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
    color: #4a4a4a;
  }
  .preferred-category-dropdown {
    width: 100%;
    margin: 5px 0;
    padding: 5px !important;
    font-size: 8px !important;
    border: 1px solid #ddd;
    border-radius: 10px;
    background-color: #ffffff;
    color: #4a4a4a;
  }
  .coins-logo {
  color: #FFFFFF; /* If the SVG inherits color */
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
  margin-left: 10px;
}
}

@media screen and (max-width: 1023px) {

.row {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
}
.coins-logo {
  color: #FFFFFF; /* If the SVG inherits color */
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
  margin-left: 10px;
}
.col-md-4 {
    order: -1; /* Move the reward section to the top */
  }

  .col-md-8 {
    order: 0; /* Ensure the main content comes after the reward section */
  }

  .progress-grid {
    display: grid !important;
    grid-template-columns: repeat(1, 1fr) !important;
    // gap: 10px;
    margin: 5px auto;
  }

}

@media only screen and (max-width: 767px) and (min-width: 480px) {
  .rightBar {
    top:100px !important;
  }
  .row {
    display: flex;
    flex-direction: column; /* Stack columns vertically */
  }

  .col-md-4 {
    order: -1; /* Move the reward section to the top */
  }

  .col-md-8 {
    order: 0; /* Ensure the main content comes after the reward section */
  }
}

@media only screen and (max-width: 767px) {
  .progress-box {
    width: 100%;
    margin-right: 2%;
    // max-width: 100%;
    // height: fit-content;
    // margin: 20px auto;
    padding: 12px 5px !important;
    border: 3px solid #ddd;
    border-radius: 15px;
  }
  .coins-logo {
  color: #FFFFFF; /* If the SVG inherits color */
  width: 20px !important;
  height: 20px !important;
  margin-right: 10px;
  margin-left: 10px;
}
  .reward-section {
    position: sticky !important;
    top: 0;
  }
  .pro-button {
    background: linear-gradient(to right, #ffffff, #e5ff22);
    color: black;
    display: flex;
    flex-direction: row;
    border: solid 1px #000;
    border-radius: 10px;
    padding: 15px;
    width: 280px !important;
    height: fit-content;
    min-width: 250px;
    min-height: 90px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    flex-grow: 1; /* Takes up available space */
    overflow: hidden;
    text-overflow: ellipsis; /* Adds "..." if text is too long */
    padding-right: 10px;
    margin: auto;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .task-action-button {
    background: linear-gradient(to right, #6a5acd, #4a90e2);
    color: white;
    border: none;
    border-radius: 10px;
    padding: 15px;
    width: 280px !important;
    // height: 150px;
    min-width: 250px;
    min-height: 100px;

    text-align: center;
    font-size: 24px;
    cursor: pointer;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .clinicaltrial-button {
    background: linear-gradient(to right, #ffffff, #ffb222);
    color: black;
    display: flex;
    flex-direction: row;
    border: solid 1px #000;
    border-radius: 10px;
    padding: 15px;
    width: 280px !important;
    height: fit-content;
    min-width: 250px;
    min-height: 90px;
    text-align: center;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    flex-grow: 1; /* Takes up available space */
    overflow: hidden;
    text-overflow: ellipsis; /* Adds "..." if text is too long */
    padding-right: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  .row {
    display: flex;
    flex-direction: column; /* Stack columns vertically */
  }

  .col-md-4 {
    order: -1; /* Move the reward section to the top */
  }

  .col-md-8 {
    order: 0; /* Ensure the main content comes after the reward section */
  }
}

.h2 {
  font-size: 15px;
  text-align: center;
}
.reward-section {
  position: sticky;
  top: 0;
  // margin-left: 5%;
}
.coins-logo {
  color: #FFFFFF; /* If the SVG inherits color */
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-left: 10px;
}

.reward-container {
  display: flex;
  flex-direction: row;
  // justify-content: center;
  // align-items: center;
  width: 100%;
  top: 0;
  // max-width: 1320px;
}
.headingbar {
  margin-bottom: 0.8vw;
}
.mainpage-header-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  width: 100%;
  // margin-bottom: 20px;
}

.mainpage-header-container h2 {
  flex-grow: 1;
  font-size: 118%;
  text-justify: auto;
  font-weight: bold;
  text-align: center;
  margin: 20px;
  line-height: 25px;
  // margin-left: 15%;
}

.verify-container {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: auto;
  width: 90%;
  border: 1px solid #000;
  border-radius: 15px;
  background-color: #e5ff22;
  margin-bottom: 20px;
  cursor: pointer;
}

.verify-container:hover {
  transform: scale(1.05);
}

.verify-container h2 {
  flex-grow: 1;
  font-size: 20px;
  text-align: center;
  margin: 0;
  // margin-left: 15%;
}

.progress-box {
  width: 100%;
  margin-right: 2%;
  // max-width: 100%;
  // height: fit-content;
  // margin: 20px auto;
  padding: 20px;
  border: 3px solid #ddd;
  border-radius: 15px;
}

.progress-box-header {
  width: 100%;
  // height: 100px;
  // max-width: 1320px;
  align-items: center;
  // margin: 20px auto;
  // padding: 20px;
  border: 1px solid #ddd;
  border-radius: 15px;
  background-color: #7878e9;
  text-align: center;
}

.progress-box-header h2 {
  flex-grow: 1;
  font-size: 16px;
  text-align: center;
  color: white;
  margin: 0;
  // margin-left: 15%;
}

.progress-grid {
  display: grid;

  grid-template-columns: repeat(2, 1fr);
  margin: 5px auto;
}

.tasks-action-buttons {
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 500px;
  align-items: center;
  margin: auto;
  gap: 10px;
  justify-content: space-evenly;
  // margin-top: 20px;
}

.checkmark {
  position: absolute;
  top: 10px;
  right: 10px;
  color: rgb(0, 255, 0);
  font-size: 24px;
  font-weight: bold;
}

.divider {
  width: 2px;
  background-color: #ccc;
  height: 100%;
}

.button-wrapper {
  position: relative;
  text-align: center;
}

.task-action-button :disabled {
  background-color: grey;
  cursor: not-allowed;
}

.task-action-button {
  background: linear-gradient(to right, #6a5acd, #4a90e2);
  color: white;
  border: none;
  border-radius: 10px;
  padding: 15px;
  width: 300px;
  // height: 150px;
  min-width: 250px;
  min-height: 100px;

  text-align: center;
  font-size: 24px;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pro-button {
  background: linear-gradient(to right, #ffffff, #e5ff22);
  color: black;
  display: flex;
  flex-direction: row;
  border: solid 1px #000;
  border-radius: 10px;
  padding: 15px;
  width: 300px;
  height: fit-content;
  min-width: 250px;
  min-height: 90px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1; /* Takes up available space */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds "..." if text is too long */
  padding-right: 10px;
  margin: auto;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.pro-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.clinicaltrial-button {
  background: linear-gradient(to right, #ffffff, #ffb222);
  color: black;
  display: flex;
  flex-direction: row;
  border: solid 1px #000;
  border-radius: 10px;
  padding: 15px;
  width: 300px;
  height: fit-content;
  min-width: 250px;
  min-height: 90px;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  cursor: pointer;
  flex-grow: 1; /* Takes up available space */
  overflow: hidden;
  text-overflow: ellipsis; /* Adds "..." if text is too long */
  padding-right: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.clinicaltrial-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.task-action-button:hover {
  transform: scale(1.1);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
.rightbox-sec {
  height: 100vh;
  width: 40%;
  // margin-left: 5vw;
}

.rightBar {
  // background: #fff;
  border-radius: 1.5vw;
  padding: 1vw 2vw 7vw 2vw;
  display: block;
  width: 100%;
  background: transparent;
  position: relative;
}

.category-dropdown {
  width: 100%;
  margin: 5px 0;
  margin-bottom: 25px;
  padding: 10px;
  font-size: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  color: #4a4a4a;
}

.category-dropdown:focus {
  outline: none;
  border-color: #6a5acd;
  box-shadow: 0 0 5px rgba(106, 90, 205, 0.5);
}

.preferred-category-dropdown {
  width: 100%;
  margin: 5px 0;
  padding: 10px;
  font-size: 10px;
  border: 1px solid #ddd;
  border-radius: 10px;
  background-color: #ffffff;
  color: #4a4a4a;
}

.preferred-category-dropdown:focus {
  outline: none;
  border-color: #6a5acd;
  box-shadow: 0 0 5px rgba(106, 90, 205, 0.5);
}

.task-container,
.reward-container {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.milestone-container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  flex-wrap: wrap;
}

button {
  cursor: pointer;
}
</style>
