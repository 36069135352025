<template>
    <div class="general-terms">
      <h1>General Terms & Conditions</h1>
      <p>These Terms & Conditions ("Agreement") govern the relationship between <strong>Mindspeller</strong> ("we," "us," "our") and you ("user," "customer," "advertiser," "respondent"). By using Mindspeller’s services, whether as a search user, consumer respondent, or advertiser, you agree to comply with and be bound by these Terms. If you do not agree, please discontinue use of our services immediately.</p>
  
      <h2>1. Scope of the Agreement</h2>
      <p>This Agreement applies to all users of Mindspeller, including:</p>
      <ul>
        <li><strong>Consumers/Respondents</strong> engaging with Mindspeller’s search and survey platform.</li>
        <li><strong>Advertisers</strong> using Mindspeller’s SaaS platform for B2B marketing and research purposes.</li>
        <li><strong>Other businesses</strong> leveraging Mindspeller’s implicit association network for brand positioning, advertising, or research.</li>
      </ul>
      <p>By using Mindspeller’s services, you confirm that:</p>
      <ul>
        <li>You are at least <strong>18 years old</strong> (or meet the minimum legal age required in your country).</li>
        <li>You have the legal authority to accept these terms on behalf of yourself or the entity you represent.</li>
      </ul>
  
      <h2>2. Mindspeller Services</h2>
      <h3>2.1 Advertising & Marketing Research Services</h3>
      <p>Mindspeller provides businesses with decision-support tools for brand positioning, advertising, and consumer research through a cloud-based SaaS platform. Services include:</p>
      <ul>
        <li><strong>Ad Placement & Niche Targeting:</strong> Advertisers can leverage our implicit association network to reach highly relevant audiences.</li>
        <li><strong>Brand Perception Insights:</strong> Businesses receive valuable brand perception data based on respondent input.</li>
        <li><strong>Interactive Consumer Engagement:</strong> Users engage with ads and marketing materials in a non-intrusive, meaningful way.</li>
      </ul>
      <p>Mindspeller retains the right to update or modify these services to ensure compliance with legal and industry standards.</p>
  
      <h3>2.2 Consumer Use of Mindspeller</h3>
      <p>Mindspeller allows consumers to interact with brand research tools, respond to implicit association tests, and explore ad-supported content. As a consumer, you agree that:</p>
      <ul>
        <li>Your participation is <strong>voluntary</strong>.</li>
        <li>Mindspeller may use <strong>anonymized data</strong> to improve its services and provide advertisers with aggregate insights.</li>
        <li>You will not engage in <strong>fraudulent activities</strong> such as automated interactions, multiple accounts, or false survey responses.</li>
      </ul>
  
      <h2>3. Account Registration & Management</h2>
      <h3>3.1 Advertisers & Business Users</h3>
      <p>If you are an advertiser or business user, you must:</p>
      <ul>
        <li>Provide <strong>accurate account information</strong> (legal business name, email, billing details).</li>
        <li>Maintain account security by <strong>safeguarding login credentials</strong>.</li>
        <li>Ensure compliance with <strong>advertising regulations</strong> and ethical marketing practices.</li>
      </ul>
  
      <h3>3.2 Consumers & Respondents</h3>
      <p>As a consumer or survey respondent, you agree that:</p>
      <ul>
        <li>Your participation in surveys, implicit association tests, or advertising interactions is <strong>voluntary</strong>.</li>
        <li>Any account created is <strong>personal and non-transferable</strong>.</li>
        <li>You will not misuse Mindspeller’s platform for <strong>fraudulent activities</strong>.</li>
      </ul>
  
      <h2>4. Data Privacy & Use of Information</h2>
      <p>Mindspeller respects user privacy and complies with <strong>GDPR</strong> and other applicable data protection laws.</p>
  
      <h3>4.1 Data Collection & Usage</h3>
      <ul>
        <li><strong>Consumers:</strong> Any data collected from survey responses, interactions, or searches is processed anonymously unless explicit consent is provided.</li>
        <li><strong>Advertisers:</strong> Business users agree that Mindspeller may use their campaign performance data for internal analytics and platform optimization.</li>
        <li><strong>Aggregated Data:</strong> Mindspeller may use anonymized, non-personally identifiable data for research and AI model training.</li>
      </ul>
      <p>For more details, refer to our <strong>Privacy Policy</strong>.</p>
  
      <h2>5. Payments, Fees & Refund Policy</h2>
      <h3>5.1 Advertisers & Business Users</h3>
      <ul>
        <li><strong>Subscription Fees:</strong> Advertisers must pay all agreed-upon fees before launching campaigns.</li>
        <li><strong>License Fees:</strong> SaaS users pay based on selected subscription tiers and usage metrics.</li>
        <li><strong>Refunds:</strong> Payments are non-refundable, except in cases where Mindspeller fails to deliver promised services due to internal technical issues.</li>
      </ul>
  
      <h2>6. Acceptable Use Policy</h2>
      <p>To maintain a safe, transparent environment, all users must abide by the following rules:</p>
      <ul>
        <li><strong>No Misuse of Services:</strong> You may not use Mindspeller to engage in deceptive, misleading, or illegal activities.</li>
        <li><strong>No Unauthorized Data Collection:</strong> Scraping, copying, or exploiting Mindspeller’s intellectual property is strictly prohibited.</li>
        <li><strong>Respect Community Guidelines:</strong> Advertisements must comply with ethical advertising standards and avoid offensive, misleading, or manipulative content.</li>
      </ul>
  
      <h2>10. Contact Information</h2>
      <p>For any questions regarding these Terms & Conditions, please contact us at:</p>
      <p><strong>📧 Email:</strong> contact@mindspeller.com</p>
      <p><strong>Last Updated:</strong> Mar 2, 2025</p>
    </div>
  </template>

  
  <style scoped>
.general-terms {
  max-width: 800px;
  margin: 0 auto;
  padding: 10px;
  font-family: Arial, sans-serif;
  line-height: 1.6;
  font-size: 0.8vw; text-align: justify; color:#858585
}

h1 {
  font-size: 28px;
  text-align: center;
  margin-bottom: 20px;
}

h2 {
  font-size: 22px;
  margin-top: 20px;
}

h3 {
  font-size: 18px;
  margin-top: 15px;
}

p {
  font-size: 16px;
  margin-bottom: 15px;
}

ul {
  list-style-type: disc;
  padding-left: 20px;
}

ul li {
  margin-bottom: 10px;
}

strong {
  color: #717171;
}

.email {
  font-weight: bold;
  color: #007bff;
}

</style>
