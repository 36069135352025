<template>
  <main>
    <section style="height: 100vh;">
      <div class="container-fluid full-width-pg">
        <div class="row">
          <div
            class="col-md-7 p-2 d-flex align-items-center justify-content-center"
            style="height: 100vh; background-color: blue;"
          >
            <div
              class="respondent-img-box"
              style="width: 100%; height: 100%; overflow: hidden;"
            >
              <a
                href="https://www.youtube.com/watch?v=c66QqSHDSNI"
                target="_blank"
              >
                <img
                  src="../../assets/respondentmap.png"
                  style="width: 100%; height: 100%; object-fit: contain;"
                />
              </a>
            </div>
          </div>
          <div class="col-md-5 ">
            <div class="row">
              <div class="col-md-12 p-3">
                <div class="user-icon-wrapper" @click="openNetworkPage">
                  <font-awesome-icon
                    :icon="['fas', 'project-diagram']"
                    alt="Login"
                    class="user-icon"
                  />
                  <span class="login-text">B2C Mindspeller Network</span>
                </div>
                <div class="free-trial-header">
                  <p class="mb-lg-2 mb-2" style="font-weight: bold; padding-top: 50px">{{ $t('new_asset.respondent_signup_header')}}</p>
              <p class="mb-lg-2 mb-2">{{ $t('new_asset.respondent_signup_subheader')}}</p>
            </div>
                <div
                  class=" d-flex justify-content-center align-items-center mt-4 margin"
                >

                  <img
                  class="logo"
                    src="../../assets/logo-with-text.png"
                    alt=""
                    style="width: auto; height: auto;"
                  />
                </div>

                <form class="p-3 p-lg-5 form-spacing row">
                  <div class="col-12">
                    <div class="form-group">
                      <label class="pb-1">{{$t('new_asset.signUp_for')}}</label>
                      <div class="dropdown-chevron-container">
                        <select
                          v-model="currentlang"
                          @change="updateIframeSrcBasedOnLang"
                          class="form-control dropdown-select"
                        >
                          <option value="" disabled>{{$t('new_asset.choose_language')}}</option>
                          <option value="en">USA (EN)</option>
                          <option value="nl">Europe (NL)</option>
                        </select>
                    </div>
                    </div>
                  </div>

                  <div class="col-12">
                    <div class="form-group mt-3">
                      <label for="exampleInputEmail1">Email:</label>
                    <input
                      type="email"
                      class="form-control"
                      id="exampleInputEmail1"
                      aria-describedby="emailHelp"
                      :placeholder="selectedNavigation ? 'Enter a Valid Email Address' : $t('new_asset.choose_network_condition')"
                      :disabled="!selectedNavigation"
                      @keydown.enter.prevent
                      v-model="emailData"
                      @input="validateEmail"
                    />
                     
                    </div>
                  </div>

                  

                  

                  <q-dialog
                    style="max-width: none !important;"
                    v-model="basic"
                    transition-show="rotate"
                    transition-hide="rotate"
                  >
                    <q-card
                      style="
                        background-color: #f6f6f6;
                        display: flex;
                        flex-direction: column;
                      "
                    >
                      <q-card-section style="max-height: 100%; overflow: auto;">
                        <General_terms></General_terms>
                      </q-card-section>

                      <q-card-actions style="margin-top: auto;">
                        <div
                          style="
                            display: flex;
                            justify-content: flex-start;
                            margin-bottom: 1vw;
                          "
                        >
                          <input
                            v-model="termsAccepted"
                            type="checkbox"
                            id="termsCheckbox"
                            style="margin-right: 1vw;"
                          />
                          <span style="font-weight: bold;"
                            >I have read and agree to the Mindspeller Terms and
                            Conditions.</span
                          >
                        </div>
                        <div
                          style="
                            display: flex;
                            justify-content: flex-end;
                            margin-bottom: 1vw;
                          "
                        >
                          <button
                            v-close-popup
                            class=""
                            style="background-color: #92a0b3; margin-right: 1vw;"
                          >
                            Cancel
                          </button>
                          <button
                            v-close-popup
                            :disabled="!termsAccepted"
                            class=""
                            style="padding: 1vw 1.5vw; font-size: 1vw;"
                            @click="proceedToPasswordSetup"
                          >
                            Agree
                          </button>
                        </div>
                      </q-card-actions>
                    </q-card>
                  </q-dialog>

                  

                  <!-- <div class="btn-col mb-0">
                    <button
                      type="button"
                      v-bind:class="getClass()"
                      @click="openModal"
                      :disabled="this.buttonDisabled"
                    >
                      Start Earning Rewards
                    </button>
                  </div> -->

                  <div class="btn-col mb-0 mt-2">
                    <button
                      class=" h-auto p-1"
                      type="button"
                      v-bind:class="getClass()"
                      @click="openModal"
                      :disabled="
                        this.buttonDisabled
                      "
                    >
                      Start
                    </button>
                  </div>

                  

                  <div class="google-login text-center mt-3">
                    <p class="mb-lg-2 mb-2" style="line-height: normal;">
                      {{$t('new_asset.already_account')}}
                      <router-link :to="{ name: 'RespondentLogin' }">Log In </router-link>
                    </p>
                  </div>
                  <div class="google-login text-center mt-3">
                    <p class="mb-lg-2 mb-2" style="line-height: normal;">
                      {{$t('new_asset.login_advertiser')}}
                      <router-link :to="{ name: 'Free Trial' }">{{$t('new_asset.start_here')}} </router-link>
                    </p>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <WorldMapModalFreeTrial
      v-if="this.mapModalVisible"
      :respondent="respondentCAS"
      @close="toggleMapModal"
    />
  </main>
  <loading-app-wide v-if="loading" />
</template>

<script>
import General_terms from "./General_terms.vue";
import axios from "axios";
import { mapActions } from "vuex";
import VueCookies from 'vue-cookies';
import Macrotellect from "./Common/Macrotellect.vue";
import WorldMapModalFreeTrial from "@/components/library/WorldMapModalFreeTrial.vue";
import AlternativeLangLink from "@/components/common/AlternativeLangLink";
import { alert_error } from "@/helpers/alert_helper.js";
import LoadingAppWide from "@/components/common/LoadingAppWide.vue";
import { isVNode, ref } from "vue";
import {
    changeLanguage
} from '@/helpers/language_helper.js';

export default {
  name: "RespondentSignup",
  components: {
    Macrotellect,
    AlternativeLangLink,
    LoadingAppWide,
    General_terms,
    WorldMapModalFreeTrial,
  },
  setup() {
    return {
      basic: ref(false),
    };
  },
  created() {
    // Use our custom getCookie method (fallback to localStorage if necessary)
    const lang = this.$store.getters.getEnvVar('VUE_APP_DEFAULT_LANGUAGE');
    changeLanguage(this.$i18n, lang);
    const manualLang = this.getCookie('manualLangSelection') || localStorage.getItem('manualLangSelection');
    if (manualLang) {
      this.currentlang = manualLang;
      const selectedNav = `CAS-${this.currentlang.toUpperCase()} Network`;
      localStorage.setItem('selectedNavigation', selectedNav);
      this.setCookie('selectedNavigation', selectedNav, 7);
    } else {
      this.currentlang = '';
    }
  },
  data: function() {
    return {
      termsAccepted: false,
      emailData: new String(),
      emailValid: new Boolean(),
      acceptedTos: false,
      loading: false,
      mapModalVisible: false,
      respondentCAS: true,
    };
  },
  computed: {
    buttonDisabled: function() {
      if (
        // this.acceptedTos == true &&
        this.emailData.length > 0 &&
        this.emailValid
      ) {
        return false;
      } else {
        return true;
      }
    },
    selectedNavigation() {
      return this.getCookie('selectedNavigation') || '';
    },
  },
  methods: {
    ...mapActions(["setSelectedNavigation"]),
    setCookie(name, value, days, domain = '.mindspeller.com') {
            const date = new Date();
            date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
            let cookieStr = `${name}=${value}; expires=${date.toUTCString()}; path=/;`;
            cookieStr += ` domain=${domain};`;
            document.cookie = cookieStr;
        },
        getCookie(name) {
        const cookieArr = document.cookie.split(";");
        for (let cookie of cookieArr) {
            let [key, value] = cookie.split("=");
            if (key.trim() === name) {
            return value;
            }
        }
        return null;
        },
        updateIframeSrcBasedOnLang() {
        // First, clear out any previous selections
        localStorage.removeItem('manualLangSelection');
        localStorage.removeItem('selectedNavigation');
        this.setCookie('manualLangSelection', "", -1);
        this.setCookie('selectedNavigation', "", -1);

        // Now, update manualLangSelection in both storage methods
        localStorage.setItem('manualLangSelection', this.currentlang);
        this.setCookie('manualLangSelection', this.currentlang, 7);

        // Build selectedNavigation string and update it as well
        const selectedNav = `CAS-${this.currentlang.toUpperCase()} Network`;
        localStorage.setItem('selectedNavigation', selectedNav);
        this.setCookie('selectedNavigation', selectedNav, 7);

        // (If needed, also update your Vuex state using setSelectedNavigation action)

        // Determine if we are on a respondent route
        const currentPath = this.$route.path;
        const isRespondentRoute = currentPath === '/respondent_signup';
        let targetUrl = '';
        if (this.currentlang === 'en') {
            targetUrl = isRespondentRoute
            ? 'https://www.mindspeller.com/#/respondent_signup'
            : 'https://www.mindspeller.com/#/free_trial';
        } else if (this.currentlang === 'nl') {
            targetUrl = isRespondentRoute
            ? 'https://cas-nl.mindspeller.com/#/respondent_signup'
            : 'https://cas-nl.mindspeller.com/#/free_trial';
        }

        // If already on the target URL reload to fetch new state; otherwise, redirect.
        if (window.location.href === targetUrl) {
            window.location.reload();
        } else {
            window.location.assign(targetUrl);
        }
        },
    openNetworkPage() {
      localStorage.removeItem("jwt_token");
      localStorage.removeItem("jwt_refresh_token");
      localStorage.removeItem("is_anonymous");
      localStorage.removeItem("pageText");
      localStorage.removeItem("pageName");
      this.$router.push({ name: "Mindspeller_Network" });
      return axios
        .post("/api/cas/token/refresh_for_network", {})
        .then((response) => {
          localStorage.setItem(
            "jwt_token",
            response.data["x-jwt-access-token"]
          );
          localStorage.setItem(
            "jwt_refresh_token",
            response.data["x-jwt-refresh-token"]
          );
          localStorage.setItem("is_anonymous", "true");
        });
    },
    openModal() {
      this.basic = true;
    },
    closeModal() {
      this.basic = false;
    },
    getClass() {
      return {
        btn3: !this.buttonDisabled,
        "disabled-btn": this.buttonDisabled,
      };
    },
    validateEmail: function() {
      const non_professional_providers = [
        // "gmail.com",
        // "yahoo.com",
        // "outlook.com",
        // "aol.com",
        // "icloud.com",
        // "hotmail.com",
        // "protonmail.com",
        // "zoho.com",
        // "mail.com",
        // "inbox.com",
        // "gmx.com",
        // "yandex.com",
        // "rocketmail.com",
        // "aim.com",
        // "lycos.com",
        // "rediffmail.com",
        // "mailinator.com",
        // "fastmail.com",
        // "tutanota.com",
        // "outlook.com",
        // "live.com",
        // "hotmail.co.uk",
        // "yahoo.co.uk",
        // "mail.ru",
        // "cox.net",
        // "earthlink.net",
        // "juno.com",
        // "btinternet.com",
        // "qq.com",
        // "163.com",
        // "126.com",
        // "yeah.net",
        // "sina.com",
        // "aliyun.com",
        // "foxmail.com",
      ];
      let regex_check = this.emailData
        .toLowerCase()
        .match(
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );

      if (regex_check == null) {
        this.emailValid = false;
        return;
      }

      const isWorkEmail = non_professional_providers.some((domain) =>
        this.emailData.toLowerCase().endsWith(domain)
      );
      if (isWorkEmail) {
        this.emailValid = false;
        return;
      }

      this.emailValid = true;
      return;
    },

    proceedToPasswordSetup: async function() {
      this.validateEmail();
      this.loading = true;
      try {
        if (this.emailValid) {
          var access = await axios.post("/api/cas/users/exist/by_email", {
            email: this.emailData,
          });
          if (access.data == true) {
            alert_error("User already exsits");
          } else {
            let new_email = await axios.post(
              "/api/cas/started_registration/create",
              {
                email: this.emailData,
                last_step: 0,
              }
            );
            changeLanguage(this.$i18n, this.$store.state.current_user.language);
            this.$router.push({
              name: "Respondent Password Setup",
              params: {
                userEmail: this.emailData,
                respondent: true,
              },
            });
          }
        } else {
          alert_error(this.$t("message.general_server_error"));
        }
      } catch (error) {
        if (error.response.status === 500) {
          alert_error(this.$t("message.general_server_error"));
        } else {
          alert_error(this.$t("login.not_a_user_yet"));
        }
      }
      this.loading = false;
    },
    toggleMapModal() {
      this.mapModalVisible = !this.mapModalVisible;
    },
  },
};
</script>

<style>
@import "../../css/onboarding_steps_style.scss";
@import "../../css/asset_style.scss";
@import "../../css/bootstrap.min.css";
@import "../../css/index.css";
@import "../../css/custom.css";

@media only screen and (max-width: 426px) {

.user-icon-wrapper {
position: fixed;
top: 5%; /* Adjust the distance from the top edge */
right: 3%; /* Adjust distance from the right edge */
background-color: black; /* Background color of the circle */
border-radius: 50%; /* Make it circular */
width: 25px !important; /* Circle size */
height: 25px !important; /* Circle size */
display: flex;
align-items: center;
justify-content: center;
cursor: pointer;
}
.row > .col-md-7 {
display: none !important; /* Hide the left column */
}
.logo{
width:250px !important
}
.form-control {
font-size: 3.1vw !important;
line-height: 5vw !important;
}
.forgot-pass .forgot-link {
    padding: 0;
    font-size: 3.2vw !important;
    text-align: right;
    line-height: 1.9vw;
    padding-top: 0px;
}
.form-check-input[type="checkbox"] {
width: 4.6vw;
height: 4.6vw;
margin-top: 0;
margin-right: 0.7vw;
margin-left: 0;
margin-bottom: 0;
}
.remember {
display: flex;
align-items: center;
justify-content: space-between;
}
.forgot-pass .forgot-link {
padding: 0;
font-size: 4vw;
text-align: right;
line-height: 1.9vw;
padding-top: 0px;
}

button {
height: 30px;
}
}

@media only screen and (max-width: 320) {
.margin {
margin: 0px !important;
}
}
.button_pay {
  padding: 0.5vw 1vw;
  background: #0a00ff;
  border-radius: 2vw;
  font-style: normal;
  font-weight: 1000;
  font-size: 0.8vw;
  color: #ffffff !important;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 1s;
  box-shadow: none !important;
  border: none;
}


.free-trial-header h2{
  font-size: 1.5vw;
  padding-top: 50px;
  text-align: center;
  font-weight: bold;
  white-space: nowrap;
}

.free-trial-header p{
  font-size: 1vw;
  line-height: 2vh;
  text-align: center;
}


.button_pay a {
  color: #ffffff !important;
}

.button_pay:hover {
  background-color: #92a0b3;
}

.user-icon-wrapper {
  position: fixed;
  top: 5%; /* Adjust the distance from the top edge */
  right: 3%; /* Adjust distance from the right edge */
  background-color: black; /* Background color of the circle */
  border-radius: 50%; /* Make it circular */
  width: 40px; /* Circle size */
  height: 40px; /* Circle size */
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.dropdown-chevron-container {
  position: relative;
  display: inline-block;
  width: 100%; /* Adjust width as needed */
}

.dropdown-select {
  appearance: none; /* Remove default browser styling */
  -webkit-appearance: none; /* For Safari */
  -moz-appearance: none; /* For Firefox */
  background-color: white;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 10px;
  width: 100%; /* Full width */
  font-size: 1rem;
  color: #333;
  cursor: pointer;
}

.dropdown-chevron-container::after {
  content: "▼"; /* Unicode for the chevron */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust spacing from the right */
  transform: translateY(-50%);
  pointer-events: none; /* Prevent interaction with the chevron */
  font-size: 0.8rem;
  color: #666;
}

.user-icon {
  color: white; /* Icon color */
  font-size: 20px; /* Adjust size as needed */
}

.login-text {
  position: absolute;
  bottom: -50px; /* Position the text below the user icon */
  left: 50%;
  transform: translateX(-50%);
  background-color: #333; /* Dark background for the text */
  color: white; /* Text color */
  padding: 5px 8px;
  border-radius: 5px;
  font-size: 0.8em;
  opacity: 0; /* Initially hidden */
  pointer-events: none; /* Ignore pointer events on the text */
  transition: opacity 0.3s ease; /* Smooth transition for appearing/disappearing */
}

.user-icon-wrapper:hover .login-text {
  opacity: 1; /* Show the text on hover */
}

.logintotext {
  display: block;
  text-align: center;
  width: 40%;
  color: #000185;
  font-weight: 700;
  font-size: 1.8vw;
  line-height: 2vw;
  text-transform: capitalize;
  color: #1b2f3f;
  margin-top: 1.5vw;
}

.user-icon-wrapper:hover .login-text {
  opacity: 1; /* Show the text on hover */
}

.map-btn-container select {
  background: white;
  border-radius: 8px;
  background-color: #0a00ff;
  border: 1px solid #000;
  font-size: 0.9em;
  padding: 10px;
  color: #fff;
  cursor: pointer;
  transition: color 0.3s ease;
  margin-left: 10px;
  animation: bounce 1.5s infinite;
  font-weight: 600;
}

.map-btn-container select:hover {
  font-weight: bold;
  background-color: #423bffb7;
}

.login-header {
  display: flex;
  align-items: center;
  justify-content: center;
  /* margin-bottom: 1.5vw; */
}

.map-toggle-btn {
  background-color: #0a00ff;
  color: white;
  font-weight: 600;
  font-size: 1vw;
  padding: 15px 10px;
  border: solid 2px #0d3a69;
  border-radius: 10px;
  margin-bottom: 1.5vw;
  animation: bounce 1.5s infinite;
  cursor: pointer;
}

.map-toggle-btn-selected {
  background-color: #0a00ff;
  color: white;
  font-weight: 600;
  font-size: 1vw;
  padding: 15px 10px;
  border: solid 2px #0d3a69;
  border-radius: 10px;
  margin-bottom: 1.5vw;
  cursor: pointer;
}

.map-toggle-btn:hover {
  background-color: #423bffb7;
  transform: scale(1.1);
}

@keyframes bounce {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-3px);
  }
}
</style>