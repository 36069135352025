<template>
    <div class="custom-purple-outline">
      <!-- Header with dropdown toggle -->
      <div class="info-header" @click="toggleDropdown">
        <font-awesome-icon :icon="['fas', 'lightbulb']" class="header-icon" />
        <span class="dropdown-title">{{ dropdownTitle }}</span>
        <font-awesome-icon :icon="isCollapsed ? ['fas', 'chevron-down'] : ['fas', 'chevron-up']" class="toggle-icon" />
      </div>
  
      <!-- Dropdown Content -->
      <div v-show="!isCollapsed" class="dropdown-content">
        <!-- If selected assets exist, show asset-checkbox components -->
        <div v-if="selected_assets_and_positionings.size > 0">
          <asset-checkbox 
            v-for="ap in selected_assets_and_positionings" 
            :key="ap" 
            :asset_and_positioning="ap"
            :show_positionings="show_positionings" 
            @removeAsset="onRemoveAsset" 
          />
        </div>
        <!-- Otherwise show search, default words, buttons, image grid etc. -->
        <div v-else>
          <div class="search-container" v-if="!$store.state.is_respondent">
            <q-icon name="search" class="search-icon" />
            <q-select 
              use-input 
              ref="select_widget" 
              v-model="selectedWord" 
              :options="options" 
              :loading="loading"
              :hint="hint" 
              @filter="filter" 
              placeholder="Search" 
              class="q-select-no-padding"
            >
              <template v-slot:no-option>
                <q-item>
                  <q-item-section class="text-grey">
                    {{ $t('message.no_match') }}
                  </q-item-section>
                </q-item>
              </template>
              <template v-slot:loading>
                <q-item>
                  <q-item-section class="loading-container">
                    <q-spinner size="30px" color="primary" />
                  </q-item-section>
                </q-item>
              </template>
            </q-select>
          </div>
          <div class="search-container" v-else>
            <word-picker-mobile @dictWordSelected="handleWordSelected" @wordRemoved="onRemoveAsset" />  
          </div>
          <!-- Default Words List -->
          <div class="default-words-list">
            <div 
              v-for="word in defaultWords" 
              :key="word.word_id" 
              class="default-word-item"
              @click="selectDefaultWord(word)"
            >
              <q-icon name="check" class="circle-check-icon" />
              <span class="default-word-text">{{ word.word }}</span>
            </div>
          </div>
          <!-- Lib Buttons -->
          <div class="lib-btn-container">
            <div v-if="!$store.state.is_respondent" class="lib-btn">
              <a class="lib-btn-small" @click="openAssetPicker">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
            <div v-else class="lib-btn">
              <a class="lib-btn-small" @click="openRedirectCas">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
          </div>
          <!-- Divider -->
          <div class="divider"></div>
          <!-- Image Grid -->
          <div class="image-grid-container">
            <div class="image-grid">
              <div 
                v-for="(payload, index) in payloads" 
                :key="payload.key" 
                class="image-grid-item"
                @click="emitAddAsset(payload)"
              >
                <img 
                  :src="payload.asset.urls.thumb_medium" 
                  :alt="payload.asset.display_name"
                  class="grid-image"
                />
              </div>
            </div>
          </div>
          <!-- Repeat Lib Buttons at bottom -->
          <div class="lib-btn-container">
            <div v-if="!$store.state.is_respondent" class="lib-btn">
              <a class="lib-btn-small" @click="openAssetPicker">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
            <div v-else class="lib-btn">
              <a class="lib-btn-small" @click="openRedirectCas">
                <q-icon name="expand_more" class="lib-icon-circle" />
                <span class="dots">...</span>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <RedirectCasPopup v-if="showRedirectCas" @close="closeRedirectCas"></RedirectCasPopup>
  </template>
  
  <script>
  import axios from 'axios';
  import WordPickerMobile from './WordPickerMobile.vue';
  import AddNewAssetDialog from '@/components/add_asset/AddNewAssetDialog.vue';
  import AssetCreator from '@/components/asset_library/AssetCreatorDashboard.vue';
  import AssetCheckbox from '@/components/target_pickers/assets/AssetCheckboxDashboard.vue';
  import RedirectCasPopup from '@/components/respondent_panel/RedirectCasPopup.vue';
  import { alert_error, genericDialog } from '@/helpers/alert_helper.js';
  
  export default {
    components: { AddNewAssetDialog, AssetCreator, AssetCheckbox, RedirectCasPopup, WordPickerMobile },
    emits: ['wordSelected', 'wordChanged', 'assetClick', 'addNewAsset', 'addAsset', 'wordRemoved'],
    props: {
      clearAfterSelection: { type: Boolean, default: true },
      hint: { type: String, default: '' },
      standalone: { type: Boolean, default: false },
      selected_assets_and_positionings: { type: Set, required: true },
      show_positionings: { type: Boolean, required: true },
      openAssetPicker: Function,
      onAddNewAsset: Function,
      onRemoveAsset: Function,
      allowed_types: { type: Array, default: () => ['images', 'words', 'multimedia'] },
      isModal: { type: String },
      background: { type: String, default: 'img-icon.png' },
    },
    data() {
      return {
        selectedWord: null,
        options: [],
        isCollapsed: true,
        loading: false,
        showRedirectCas: false,
        last_search: '',
        defaultWordData: {
          COURAGE: { value: { word: "COURAGE", word_id: 4741 }, label: "COURAGE" },
          TASTE: { value: { word: "TASTE", word_id: 20331 }, label: "TASTE" },
          AUTHENTIC: { value: { word: "AUTHENTIC", word_id: 1481 }, label: "AUTHENTIC" }
        },
        defaultWords: [
          { word: 'AUTHENTIC', word_id: 1481 },
          { word: "COURAGE", word_id: 4741 },
          { word: "TASTE", word_id: 20331 },
        ],
        payloads: [
                {
                    asset: {
                        id: 1935,
                        state: 2,
                        brand: 0,
                        display_name: "shutterstock_1284088828",
                        top_associations:
                            "SINGING, SING, KARAOKE, FRIENDS, MIKE, FUN, GIRLS, PARTY, MUSIC, FRIEND, FRIENDSHIP, MICROPHONE, DUET, ENJOY, ENTERTAINMENT, SONG, DRINK, LAUGHTER, JOY, HAPPY",
                        labels: "SMILE,TARTAN,FASHION,TEXTILE,FLASH PHOTOGRAPHY",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 5.66232,
                        total_count: 15,
                        word: {
                            word: "SHUTTERSTOCK_1284088828",
                            created_at: "2021-10-12T16:03:03",
                            updated_at: "2021-10-12T16:03:03",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            external: 1,
                            word_id: 312740,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_6e5a179e-d3b1-4749-820e-68a9d5e4c007",
                        },
                    },
                    positioning: {
                        id: 502,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>The concepts or stimuli exposed to you can comprise imagery, words, multimedia, logos, symbols, etc.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typos.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-10-26",
                        url_slug: "mdsp_auto115",
                        redirect_url: "",
                        created_at: "2021-10-12T16:03:05",
                        updated_at: "2021-11-25T18:55:41",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-10-12",
                    },
                    key: "193520211026",
                },
                {
                    asset: {
                        id: 1379,
                        state: 2,
                        brand: 0,
                        display_name: "583571431",
                        top_associations:
                            "HAPPY, KIDS, FLOWERS, CHILDREN, SPRING, GRASS, FLOWER, FUN, SUMMER, NATURE, DAISY, DAISIES, CHILDHOOD, SMILE, HAPPINESS, CHILD, GARDEN, FRIENDS, BRIGHT, OUTDOORS",
                        labels: "SMILE,HAND,PLANT,FLOWER,GREEN",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 6.66167,
                        total_count: 89,
                        word: {
                            word: "583571431",
                            created_at: "2021-04-12T11:24:46",
                            updated_at: "2021-04-12T11:24:46",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            external: 1,
                            word_id: 309249,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "792e6221-5947-41a1-aa7f-a3d23fc5843e",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_792e6221-5947-41a1-aa7f-a3d23fc5843e",
                        },
                    },
                    positioning: {
                        id: 438,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-04-20",
                        url_slug: "mdsp_auto82",
                        redirect_url: "",
                        created_at: "2021-04-12T11:24:46",
                        updated_at: "2021-04-23T16:47:19",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-04-12",
                    },
                    key: "137920210420",
                },
                {
                    asset: {
                        id: 1947,
                        state: 2,
                        brand: 0,
                        display_name: "shutterstock_1938550555",
                        top_associations:
                            "ART, HOME, COUPLE, FAMILY, FRAME, DECOR, PICTURE, WALL, HANG, PAINTING, SOFA, LOVE, PHOTO, NEW HOME, DECORATING, MAN, HOUSE, DESIGN, WOMEN, HAPPY",
                        labels: "FURNITURE,PROPERTY,COMFORT,INTERIOR DESIGN,WALL",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 4.40072,
                        total_count: 25,
                        word: {
                            word: "SHUTTERSTOCK_1938550555",
                            created_at: "2021-10-12T16:03:03",
                            updated_at: "2021-10-12T16:03:03",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "894c6a24-4c28-4e17-b163-99b625ecbddf",
                            external: 1,
                            word_id: 312752,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "894c6a24-4c28-4e17-b163-99b625ecbddf",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_894c6a24-4c28-4e17-b163-99b625ecbddf",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_894c6a24-4c28-4e17-b163-99b625ecbddf",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_894c6a24-4c28-4e17-b163-99b625ecbddf",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_894c6a24-4c28-4e17-b163-99b625ecbddf",
                        },
                    },
                    positioning: {
                        id: 502,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-10-26",
                        url_slug: "mdsp_auto115",
                        redirect_url: "",
                        created_at: "2021-10-12T16:03:05",
                        updated_at: "2021-11-25T18:55:41",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-10-12",
                    },
                    key: "194720211026",
                },
                {
                    asset: {
                        id: 1915,
                        state: 2,
                        brand: 0,
                        display_name: "shutterstock_635611256",
                        top_associations:
                            "DRINK, BAR, BARTENDER, COCKTAIL, ALCOHOL, DRINKS, WINE, MARTINI, PARTY, MAN, GLASS, TASTY, MIX, MIXOLOGY, FUN, DRUNK, FILTER, RELAXING, YUMMY, MIXOLOGIST",
                        labels: "TABLEWARE,DRINKWARE,MARTINI GLASS,STEMWARE,LIQUID",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 4.52654,
                        total_count: 2,
                        word: {
                            word: "SHUTTERSTOCK_635611256",
                            created_at: "2021-10-12T16:03:03",
                            updated_at: "2021-10-12T16:03:03",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "d165f412-6928-4e35-afcd-ddfd966e88bc",
                            external: 1,
                            word_id: 312720,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "d165f412-6928-4e35-afcd-ddfd966e88bc",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_d165f412-6928-4e35-afcd-ddfd966e88bc",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_d165f412-6928-4e35-afcd-ddfd966e88bc",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_d165f412-6928-4e35-afcd-ddfd966e88bc",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_d165f412-6928-4e35-afcd-ddfd966e88bc",
                        },
                    },
                    positioning: {
                        id: 500,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-10-26",
                        url_slug: "mdsp_auto113",
                        redirect_url: "",
                        created_at: "2021-10-12T16:03:04",
                        updated_at: "2021-11-05T17:48:39",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-10-12",
                    },
                    key: "191520211026",
                },
                {
                    asset: {
                        id: 1079,
                        state: 2,
                        brand: 0,
                        display_name: "373369408",
                        top_associations:
                            "BUSINESS, CITY, SUCCESS, SUIT, MAN, TOP, BUSINESSMAN, WORK, CLOUD, PROFESSIONAL, MOUNTAIN, BRIEFCASE, ROCK, WEIRD, CORPORATE, SMART, POWER, SKYSCRAPER, BUILDINGS, CAREER",
                        labels: "BUILDING,WORLD,STANDING,ATMOSPHERIC PHENOMENON,FLASH PHOTOGRAPHY",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 4.78032,
                        total_count: 46,
                        word: {
                            word: "373369408",
                            created_at: "2021-02-01T13:58:00",
                            updated_at: "2021-02-01T13:58:00",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            external: 0,
                            word_id: 306987,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_c8895ae2-3313-4afa-9ae5-0cfd3bf988fc",
                        },
                    },
                    positioning: {
                        id: 381,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-02-08",
                        url_slug: "mdsp_auto59",
                        redirect_url: "",
                        created_at: "2021-02-01T13:58:02",
                        updated_at: "2021-05-14T15:41:08",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-02-01",
                    },
                    key: "107920210208",
                },
                {
                    asset: {
                        id: 724,
                        state: 2,
                        brand: 0,
                        display_name: "1320625385",
                        top_associations:
                            "BOOK, TREE, KNOWLEDGE, LIBRARY, BOOKS, READING, NATURE, LIFE, LEARNING, BONSAI, GROWTH, BRIGHT, BROCCOLI, EDUCATION, GREEN, READ, PEACE, GROW, SUN, LEARN",
                        labels: "PUBLICATION,WOOD,BOOK,LINE,BOOKCASE",
                        passion: 0,
                        link_word_id: null,
                        map_date: null,
                        intended_association: null,
                        blocked: 0,
                        lifecycle: 4,
                        clarity_score: 6.42149,
                        total_count: 40,
                        word: {
                            word: "1320625385",
                            created_at: "2021-01-20T13:00:57",
                            updated_at: "2021-01-20T13:00:57",
                            asset_mime_type: "image/jpeg",
                            file_identifier: "3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            external: 0,
                            word_id: 302902,
                            type_: {
                                id: 2,
                                title: "image / logo",
                                created_at: "0000-00-00 00:00:00",
                                updated_at: "0000-00-00 00:00:00",
                                isMultimedia: true,
                                isImage: true,
                                isAudio: false,
                                isVideo: false,
                            },
                            image_url: "",
                        },
                        candidate_word: [],
                        asset_mime_type: "image/jpeg",
                        file_identifier: "3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                        type_: {
                            id: 2,
                            title: "image / logo",
                            created_at: "0000-00-00 00:00:00",
                            updated_at: "0000-00-00 00:00:00",
                            isMultimedia: true,
                            isImage: true,
                            isAudio: false,
                            isVideo: false,
                        },
                        urls: {
                            thumb_small:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wt_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            thumb_medium:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wtt_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            thumb_large:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wttt_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                            file:
                                "https://mindspeller-assets-prod-en.s3.eu-central-1.amazonaws.com/wf_3adf80a3-6d83-4002-a6ce-3c6bb985e112",
                        },
                    },
                    positioning: {
                        id: 355,
                        company_name: "AUTOMATIC MINDSPELLER SURVEY",
                        title: "AUTOMATIC MINDSPELLER SURVEY",
                        subtitle: "AUTOMATIC MINDSPELLER SURVEY",
                        info_sentence:
                            "<p>Welcome to the Mindspeller Survey!</p><p>As soon as you are ready to start the survey by clicking the button below, you will be exposed to maximum 20 random concepts for which you can provide 3 spontaneous associations per concept.</p><p>Since your spontaneity is important, the less you contemplate your input, the better! </p><p>Try to avoid plurals, multiple words and typo's.</p><p>Click the 'next' button below to start and have fun!</p>",
                        to: "2021-01-27",
                        url_slug: "mdsp_auto33",
                        redirect_url: "",
                        created_at: "2021-01-20T13:00:59",
                        updated_at: "2021-02-12T15:17:10",
                        ingested: 1,
                        type: {
                            id: 4,
                            type: "Mechanical Turk",
                            is_mturk: true,
                        },
                        from_: "2021-01-20",
                    },
                    key: "72420210127",
                },
            ],
        
      };
    },
    computed: {
      dropdownTitle() {
        if (this.selected_assets_and_positionings.size > 0) {
          const firstAsset = this.selected_assets_and_positionings.values().next().value;
          return firstAsset.asset.file_identifier ? "Value: Image" : "Value: " + firstAsset.asset.display_name;
        } else {
          return "Select your value";
        }
      },
    },
    methods: {
      reset() { this.selectedWord = null; },
      handleWordSelected(word) { this.selectedWord = word; this.$emit('wordSelected', word); },
      toggleDropdown() { this.isCollapsed = !this.isCollapsed; },
      filter: async function (val, update, abort) {
        await update(this.fetchWords(val));
      },
      openRedirectCas() { this.showRedirectCas = true; },
      closeRedirectCas() { this.showRedirectCas = false; },
      fetchWords: async function (val) {
        if (val === '') { this.options = []; this.last_search = val; }
        else {
          if (val !== this.last_search) {
            this.loading = true;
            const payload = { draw: 1, search: { value: val.toLowerCase() }, start: 0, length: 8 };
            const response = await axios.post('/api/cas/words/search', payload);
            const options = response.data.data.map((w) => ({
              value: w,
              label: w.has_access ? `✔ ${w.word}` : w.word,
            }));
            this.options = options;
            this.last_search = val;
            this.$refs.select_widget.showPopup();
            this.loading = false;
          }
        }
      },
      selectDefaultWord(word) {
        const wordData = this.defaultWordData[word.word];
        if (wordData) { this.selectedWord = wordData; this.toggleDropdown(); }
      },
      onAddNewAsset() { this.$refs['add-new-asset-dialog'].open(); },
      emitAddAsset(payload) { this.$emit("addAsset", payload); },
      iaSelect: async function (word_object) {
        const wordKey = word_object.word;
        if (this.defaultWordData[wordKey]) { 
          this.$emit('wordSelected', word_object); 
        } else {
          const payload = { word_ids: [word_object.word_id] };
          const access = await axios.post('/api/cas/words/check_access', payload);
          if (access.data[word_object.word_id]) {
            this.$emit('wordSelected', word_object);
          } else {
            const price = this.$store.getters.get_price('public_word_asset');
            const dialog_options = {
              title: this.$t('message.purchase_ia', { word: word_object.word }),
              text: this.$t('message.ia_cost', { price: price }),
              confirmButtonText: this.$t('message.add'),
              cancelButtonText: this.$t('message.cancel'),
            };
            const dialog_result = await genericDialog(dialog_options);
            if (dialog_result.isConfirmed) {
              const url = `/api/cas/words/request_access_new`;
              const response = await axios.post(url, { word_ids: [word_object.word_id] });
              if (response.data.access_granted) {
                this.$emit('wordSelected', word_object);
                this.$store.dispatch('get_mindcoin_balance');
              }
              if (response.data.insufficient_credits) {
                alert_error(this.$t('message.insufficient_credits_for_ia'));
              }
              if (response.data.read_only) {
                alert_error(this.$t('message.read_only_user_no_ia'));
              }
            }
          }
        }
      },
    },
    watch: {
      selectedWord(new_value) {
        if (new_value !== null) {
          this.iaSelect(new_value.value);
          if (this.clearAfterSelection) { this.reset(); }
        } else {
          this.$emit('wordChanged', new_value);
        }
      },
    },
  };
  </script>
  
  <style scoped>
  /* Base Mobile-First Styles */
  .custom-purple-outline {
    background-color: #f9fbfd;
    border: 1px solid #7878e9;
    border-radius: 8px;
    padding: 5px;
    margin: 20px auto;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 600px;
    transition: max-height 0.3s ease;
  }
  
  .info-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    font-size: 1em;
    font-weight: bold;
    border: 1px solid #eef3fa;
    border-radius: 8px;
    color: #333;
    background-color: #eef3fa;
    cursor: pointer;
  }
  
  .header-icon {
    color: #7878e9;
  }
  
  .toggle-icon {
    color: #000;
    transition: transform 0.3s;
  }
  
  .info-header:hover {
    background-color: #a2c9ff;
  }
  
  .dropdown-title {
    font-size: 0.9em;
    flex: 1;
    text-align: center;
    margin: 0 10px;
  }
  
  .dropdown-content {
    margin-top: 10px;
  }
  
  /* Search Container */
  .search-container {
    display: flex;
    align-items: center;
    position: relative;
    padding: 5px;
  }
  
  /* Default Words List */
  .default-words-list {
    display: flex;
    flex-direction: column;
    padding: 5px;
  }
  
  .default-word-item {
    display: flex;
    align-items: center;
    padding: 8px;
    border-radius: 5px;
    margin: 5px 0;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.1s;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }
  
  .default-word-item:hover {
    background-color: #a2c9ff;
    transform: scale(1.02);
  }
  
  .default-word-text {
    flex: 1;
    font-size: 0.8em;
    color: #333;
  }
  
  .circle-check-icon {
    background-color: black;
    color: white;
    border-radius: 50%;
    padding: 2px;
    font-size: 0.6em;
    margin-right: 10px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  
  /* Lib Button */
  .lib-btn-container {
    display: flex;
    justify-content: flex-start;
    margin: 10px 0;
    gap: 10px;
  }
  
  .lib-btn-small {
    color: black;
    padding: 8px 20px;
    border-radius: 15px;
    font-size: 0.9em;
    text-decoration: none;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .lib-btn-small:hover {
    background-color: #a2c9ff;
  }
  
  .lib-icon-circle {
    font-size: 1em;
    color: black;
    border: 2px solid black;
    border-radius: 50%;
    width: 1em;
    height: 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }
  
  .dots {
    margin-left: 10px;
    color: black;
  }
  
  /* Divider */
  .divider {
    height: 1px;
    background-color: #ddd;
    width: 100%;
    margin: 15px 0;
  }
  
  /* Image Grid */
  .image-grid-container {
    overflow-x: auto;
    padding: 5px;
  }
  
  .image-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
    grid-gap: 10px;
    width: 100%;
  }
  
  .image-grid-item {
    position: relative;
    width: 100%;
    padding-top: 100%;
    border-radius: 12px;
    overflow: hidden;
    background-color: #f0f0f0;
    cursor: pointer;
    transition: transform 0.2s ease-in-out;
  }
  
  .image-grid-item:hover {
    transform: scale(1.1);
  }
  
  .grid-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  /* Media Queries for Larger Screens */
  @media screen and (min-width: 1024px) {
    .custom-purple-outline {
      max-width: 800px;
    }
  }
  
  @media screen and (min-width: 1440px) {
    .custom-purple-outline {
      max-width: 1000px;
    }
  }
  </style>
  